import {
  Alert,
  Box,
  Button,
  Container,
  SpaceBetween,
  Spinner,
} from '@cloudscape-design/components';
import { AsyncState } from 'react-use/lib/useAsyncFn';
import { AsyncStateRetry } from 'react-use/lib/useAsyncRetry';

export function LoadingState() {
  return (
    <Container>
      <Box variant="p">Loading... </Box>
      <Spinner size="large" variant="normal" />
    </Container>
  );
}

export function EmptyState() {
  return (
    <Container>
      <Box variant="p"> </Box>
    </Container>
  );
}

export function ErrorState(props: { error: Error; onRetry?: () => void }) {
  return (
    <Alert
      action={props.onRetry ? <Button onClick={props.onRetry}>Retry</Button> : undefined}
      header={props.error.name}
      statusIconAriaLabel="Error"
      type="error"
    >
      {props.error.message}
    </Alert>
  );
}
