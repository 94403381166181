import { useAsync, useAsyncRetry } from 'react-use';
import { AsyncStateRetry } from 'react-use/lib/useAsyncRetry';

import { Frame, Question, Theme, FrameMeasurement, QuestionTheme } from './types';

// export const questionThemes: QuestionTheme[] = 'questionThemes.json';

// export const questions: Question[] = 'questions.json';

// export const frames: Frame[] = 'frames.json';

// export const themes: Theme[] = 'themes.json';

// export const time_data: FrameMeasurement[] = "covid19-frame-predict-v3_stance-ts.json";

export function useData<T>(path: string): AsyncStateRetry<T> {
  const state = useAsyncRetry(async () => {
    const response = await fetch(`https://cdn.maxnlp.ai/${path}`);

    if (response.status !== 200) {
      throw new Error(`Could not find ${path}`);
    }

    const data = await response.json();

    return data as T;
  });

  return state;
}

export const questionThemes: QuestionTheme[] = [];
export const questions: Question[] = [];
export const frames: Frame[] = [];
export const themes: Theme[] = [];
export const time_data: FrameMeasurement[] = [];
