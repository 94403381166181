import { ReactNode } from 'react';
import { Box, SpaceBetween } from '@cloudscape-design/components';

export type Education = {
  title: string;
  company: string;
  range: string;
  logo: string;
  location?: string;
  url?: string;
  content?: ReactNode;
  end?: number;
};

export const Educations: Education[] = [
  {
    title: 'Doctor of Philosophy - PhD, Computer Science',
    company: 'The University of Texas at Dallas',
    logo: 'https://cdn.maxnlp.ai/images/utd.jpg',
    url: 'https://www.linkedin.com/school/university-of-texas-at-dallas/',
    location: 'Dallas, Texas, United States',
    range: '2020 - Present',
    content: (
      <SpaceBetween direction="vertical" size="xs">
        <Box variant="p">
          Research focus is on Natural Language Processing, Social Media, Public Health, and Machine
          Learning.
        </Box>
      </SpaceBetween>
    ),
  },
  {
    title: 'Master of Science - MS, Computer Science',
    company: 'The University of Texas at Dallas',
    logo: 'https://cdn.maxnlp.ai/images/utd.jpg',
    url: 'https://www.linkedin.com/school/university-of-texas-at-dallas/',
    location: 'Dallas, Texas, United States',
    range: '2019 - 2020',
    end: 2020,
    content: (
      <SpaceBetween direction="vertical" size="xs">
        <Box variant="p">Intelligent Systems Specialization</Box>
      </SpaceBetween>
    ),
  },
  {
    title: 'Bachelor of Science - BS, Computer Science',
    company: 'The University of Texas at Dallas',
    logo: 'https://cdn.maxnlp.ai/images/utd.jpg',
    url: 'https://www.linkedin.com/school/university-of-texas-at-dallas/',
    location: 'Dallas, Texas, United States',
    range: '2015 - 2018',
    end: 2018,
  },
  {
    title: 'High School Diploma',
    company: 'Coram Deo Academy',
    logo: 'https://cdn.maxnlp.ai/images/cda.jpg',
    url: 'https://www.linkedin.com/school/coram-deo-academy/',
    location: 'Lewisville, Texas, United States',
    range: '2012 - 2015',
    end: 2015,
  },
];

export default Educations;
