import {
  AppLayout,
  ContentLayout,
  Header,
  SpaceBetween,
  Container,
  ColumnLayout,
  Box,
} from '@cloudscape-design/components';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';

import { LocalNavigation, Breadcrumbs, LocalNavigationProps } from '../../../navigation';
import { StanceTimeGraph } from '../../stance/time';
import { FramesTable } from '../../table';
import { StanceDonut } from '../../stance/stance';
import { CategoryStanceDonut } from '../../stance/category';
import { EmptyState, ErrorState, LoadingState } from '../../../common/data/loading';
import { FrameContext } from '../../../common/data/context';

export default function MoralityPage(props: LocalNavigationProps) {
  const params = useParams();

  const state = useContext(FrameContext).state;

  const moralityId = params.moralityId ?? '';
  let content = (
    <Container>
      <Box>Moral Foundation data not found</Box>
    </Container>
  );
  let header = <Header variant="h1">Unknown Moral Foundation</Header>;

  if (state.loading) {
    content = <LoadingState />;
    header = <Header variant="h1">Loading...</Header>;
  }

  if (state.error) {
    content = (
      <SpaceBetween direction="vertical" size="xxs">
        <EmptyState />
        <ErrorState error={state.error} onRetry={state.retry} />
      </SpaceBetween>
    );
    header = <Header variant="h1">Error</Header>;
  }

  if (moralityId !== undefined && state.value !== undefined) {
    const info = state.value?.moralLookup[moralityId];

    if (info !== undefined) {
      header = (
        <SpaceBetween direction="vertical" size="xxs">
          <Header variant="h1">
            {info.name} Moral Foundation Value for COVID-19 Vaccine Framings
          </Header>
          <Header variant="h2">{info.description}</Header>
        </SpaceBetween>
      );
      content = (
        <SpaceBetween direction="vertical" size="l">
          <Container header={<Header variant="h2">{info.name} Categories & Tweets</Header>}>
            <ColumnLayout columns={2} variant="text-grid">
              <CategoryStanceDonut max_date={info.max_date} stanceInfo={info.taxStance} />
              <StanceDonut
                max_date={info.max_date}
                total={info.total}
                totalAccept={info.totalAccept}
                totalReject={info.totalReject}
              />
            </ColumnLayout>
          </Container>
          <StanceTimeGraph
            acceptSeries={info.acceptSeries}
            header={<Header variant="h2">{info.name} Tweets Over Time</Header>}
            rejectSeries={info.rejectSeries}
          />
          <FramesTable
            frames={info.frames}
            header={
              <SpaceBetween direction="vertical" size="xxxs">
                <Header variant="h2">Frames Evoking {info.name}</Header>
                <Header variant="h3">{info.description}</Header>
              </SpaceBetween>
            }
            questions={state.value!.questions}
            disableUrlQueryParams
          />
        </SpaceBetween>
      );
    }
  }

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Frames', href: '/frames' },
            { text: 'Moral Foundations', href: '/frames/moralities' },
            { text: params.moralityId ?? '?', href: '' },
          ]}
        />
      }
      content={
        <ContentLayout header={<Container header={header} disableContentPaddings />}>
          {content}
        </ContentLayout>
      }
      contentType="default"
      navigation={<LocalNavigation />}
      navigationOpen={props.navigationOpen}
      navigationWidth={250}
      toolsHide
      onNavigationChange={(event) => props.setNavigationOpen(event.detail.open)}
    />
  );
}
