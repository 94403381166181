import { Container, Link, SpaceBetween, Table } from '@cloudscape-design/components';
import PropertyFilter, { PropertyFilterProps } from '@cloudscape-design/components/property-filter';
import Select, { SelectProps } from '@cloudscape-design/components/select';
import { useNavigate } from 'react-router-dom';

import { moralityValues, taxonomyValues, taxNames } from '../common/data/frames';
import { FrameInfo, Question } from '../common/data/types';
import { useFilterSortParams } from '../common/filter/filter';

export type FramesTableProps = {
  frames: FrameInfo[];
  questions: Question[];
  disableUrlQueryParams?: boolean;
  disableQuestions?: boolean;
  disableTaxonomy?: boolean;
  disableMorality?: boolean;
  header?: React.ReactNode;
};

function getOptions(disableTaxonomy?: boolean, disableMorality?: boolean) {
  const options: SelectProps.Option[] = [
    { label: 'Default Sorting', value: 'default' },
    { label: 'Tweets', value: 'tweets', description: 'Sort by total Tweets' },
    { label: 'Most Accepted', value: 'accept', description: 'Sort by most Accepted' },
    { label: 'Most Reject', value: 'reject', description: 'Sort by most Rejected' },
  ];

  if (!disableTaxonomy) {
    options.push({ label: 'Category', value: 'taxonomy', description: 'Sort by Category' });
  }

  if (!disableMorality) {
    options.push({
      label: 'Moralities',
      value: 'moralities',
      description: 'Sort by Moral Foundations',
    });
  }

  return options;
}

function getColumnFiltering(
  questions: Question[],
  disableQuestions?: boolean,
  disableTaxonomy?: boolean,
  disableMorality?: boolean,
) {
  const filteringOptions: PropertyFilterProps.FilteringOption[] = [];
  const filteringProperties: PropertyFilterProps.FilteringProperty[] = [];
  const visibleColumns: string[] = ['text'];

  if (!disableTaxonomy) {
    filteringOptions.push(
      ...taxonomyValues.map((taxonomy) => ({ propertyKey: 'taxonomy', value: taxNames[taxonomy] })),
    );
    filteringProperties.push({
      key: 'taxonomy',
      operators: ['=', '!='],
      propertyLabel: 'Category',
      groupValuesLabel: 'Categories',
    });
    visibleColumns.push('taxonomies');
  }

  if (!disableMorality) {
    filteringOptions.push(
      ...moralityValues.map((morality) => ({ propertyKey: 'morality', value: morality })),
    );
    filteringProperties.push({
      key: 'morality',
      operators: ['=', '!='],
      propertyLabel: 'Moral Foundation',
      groupValuesLabel: 'Moral Foundation values',
    });
    visibleColumns.push('moralities');
  }

  if (!disableQuestions) {
    filteringOptions.push(
      ...questions.map((question) => ({ propertyKey: 'question', value: question.q_id })),
    );
    filteringProperties.push({
      key: 'question',
      operators: ['=', '!='],
      propertyLabel: 'Question',
      groupValuesLabel: 'Question text',
    });
    visibleColumns.push('question');
  }

  filteringProperties.push({
    key: 'text',
    operators: [':', '!:'],
    propertyLabel: 'Text',
    groupValuesLabel: 'Framing text',
    defaultOperator: ':',
  });
  visibleColumns.push('tweets');

  return { filteringOptions, filteringProperties, visibleColumns };
}

function joinFilter(match: boolean, otherMatch: boolean, operation: string) {
  if (operation === 'and') {
    return match && otherMatch;
  }

  return match || otherMatch;
}

export function FramesTable(props: FramesTableProps) {
  const navigate = useNavigate();
  const { query, setQuery, selectedOption, setSelectedOption } = useFilterSortParams(
    props.disableUrlQueryParams,
  );
  const options = getOptions(props.disableTaxonomy, props.disableMorality);

  const { filteringOptions, filteringProperties, visibleColumns } = getColumnFiltering(
    props.questions,
    props.disableQuestions,
    props.disableTaxonomy,
    props.disableMorality,
  );

  const filteredInfo = props.frames.filter((info) => {
    let matches = query.operation === 'and' || query.tokens.length === 0;
    query.tokens.forEach((token) => {
      let otherMatch = false;

      if (token.propertyKey === 'morality') {
        if (token.operator === '=') {
          otherMatch = info.moralities.includes(token.value);
        } else if (token.operator === '!=') {
          otherMatch = !info.moralities.includes(token.value);
        }
      } else if (token.propertyKey === 'taxonomy') {
        if (token.operator === '=') {
          otherMatch = info.themes.map((t) => taxNames[t.taxonomy]).includes(token.value);
        } else if (token.operator === '!=') {
          otherMatch = !info.themes.map((t) => taxNames[t.taxonomy]).includes(token.value);
        }
      } else if (token.propertyKey === 'question') {
        if (token.operator === '=') {
          otherMatch = info.question.q_id === token.value;
        } else if (token.operator === '!=') {
          otherMatch = !(info.question.q_id !== token.value);
        }
      } else if (token.propertyKey === 'text') {
        if (token.operator === ':') {
          otherMatch =
            info.frame.text.toLowerCase().includes(token.value.toLowerCase()) ||
            info.frame.details.toLowerCase().includes(token.value.toLowerCase());
        } else if (token.operator === '!:') {
          otherMatch = !(
            info.frame.text.toLowerCase().includes(token.value.toLowerCase()) ||
            info.frame.details.toLowerCase().includes(token.value.toLowerCase())
          );
        }
      }
      matches = joinFilter(matches, otherMatch, query.operation);
    });

    return matches;
  });

  const sortedInfo = filteredInfo.sort((a, b) => {
    if (selectedOption.value === 'default') {
      return 0;
    } else if (selectedOption.value === 'tweets') {
      return b.total - a.total;
    } else if (selectedOption.value === 'accept') {
      return b.totalAccept / b.total < a.totalAccept / a.total ? -1 : 1;
    } else if (selectedOption.value === 'reject') {
      return b.totalReject / b.total < a.totalReject / a.total ? -1 : 1;
    } else if (selectedOption.value === 'moralities') {
      return b.moralities.join(',') > a.moralities.join(',') ? -1 : 1;
    } else if (selectedOption.value === 'taxonomy') {
      return b.taxonomies.map((t) => taxNames[t]).join(',') <
        a.taxonomies.map((t) => taxNames[t]).join(',')
        ? -1
        : 1;
    }

    return 0;
  });

  return (
    <Container header={props.header}>
      <SpaceBetween size="m">
        <SpaceBetween direction="horizontal" size="s">
          <PropertyFilter
            filteringOptions={filteringOptions}
            filteringProperties={filteringProperties}
            i18nStrings={{
              filteringAriaLabel: 'your choice',
              dismissAriaLabel: 'Dismiss',
              filteringPlaceholder: 'Filter frames',
              groupValuesText: 'Values',
              groupPropertiesText: 'Properties',
              operatorsText: 'Operators',
              operationAndText: 'and',
              operationOrText: 'or',
              operatorLessText: 'Less than',
              operatorLessOrEqualText: 'Less than or equal',
              operatorGreaterText: 'Greater than',
              operatorGreaterOrEqualText: 'Greater than or equal',
              operatorContainsText: 'Contains',
              operatorDoesNotContainText: 'Does not contain',
              operatorEqualsText: 'Equals',
              operatorDoesNotEqualText: 'Does not equal',
              editTokenHeader: 'Edit filter',
              propertyText: 'Property',
              operatorText: 'Operator',
              valueText: 'Value',
              cancelActionText: 'Cancel',
              applyActionText: 'Apply',
              allPropertiesLabel: 'All properties',
              tokenLimitShowMore: 'Show more',
              tokenLimitShowFewer: 'Show fewer',
              clearFiltersText: 'Clear filters',
              removeTokenButtonAriaLabel: (token) =>
                `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
              enteredTextLabel: (text) => `Use: "${text}"`,
            }}
            query={query}
            expandToViewport
            onChange={({ detail }) => setQuery(detail as PropertyFilterProps.Query)}
          />
          <Select
            options={options}
            selectedAriaLabel="Selected"
            selectedOption={
              (selectedOption.label
                ? selectedOption
                : { label: 'Default Sorting', value: 'default' }) as SelectProps.Option
            }
            onChange={({ detail }) => setSelectedOption(detail.selectedOption)}
          />
        </SpaceBetween>
        <Table
          columnDefinitions={[
            {
              id: 'text',
              header: 'Framing',
              cell: (item) => (
                <Link
                  href={`/frames/${item.frame.f_id}`}
                  variant="secondary"
                  onFollow={(event) => {
                    event.preventDefault();
                    navigate(event.detail.href!);
                  }}
                >
                  {item.frame.text}
                </Link>
              ),
            },
            {
              id: 'taxonomies',
              header: 'Categories',
              cell: (item) =>
                item.taxonomies.length > 0
                  ? item.taxonomies
                      .map<React.ReactNode>((t) => (
                        <Link
                          key={`${item.frame.f_id}-${t}`}
                          href={`/frames/categories/${t}`}
                          variant="secondary"
                          onFollow={(event) => {
                            event.preventDefault();
                            navigate(event.detail.href!);
                          }}
                        >
                          {taxNames[t]}
                        </Link>
                      ))
                      .reduce((prev, curr) => [prev, ', ', curr])
                  : '',
            },
            {
              id: 'moralities',
              header: 'Moral Foundations',
              cell: (item) =>
                item.moralities.length > 0
                  ? item.moralities
                      .map<React.ReactNode>((mf) => (
                        <Link
                          key={`${item.frame.f_id}-${mf}`}
                          href={`/frames/moralities/${mf}`}
                          variant="secondary"
                          onFollow={(event) => {
                            event.preventDefault();
                            navigate(event.detail.href!);
                          }}
                        >
                          {mf}
                        </Link>
                      ))
                      .reduce((prev, curr) => [prev, ', ', curr])
                  : '',
            },
            {
              id: 'question',
              header: 'Question',
              cell: (item) => (
                <Link
                  key={`${item.frame.f_id}-${item.question.q_id}`}
                  href={`/frames/questions/${item.question.q_id}`}
                  variant="secondary"
                  onFollow={(event) => {
                    event.preventDefault();
                    navigate(event.detail.href!);
                  }}
                >
                  {item.question.q_text}
                </Link>
              ),
            },
            {
              id: 'tweets',
              header: 'Tweets',
              cell: (item) => item.total.toLocaleString('en-US'),
            },
          ]}
          items={sortedInfo}
          variant="embedded"
          visibleColumns={visibleColumns}
          wrapLines
        />
      </SpaceBetween>
    </Container>
  );
}
