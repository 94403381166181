import { Box, Button, PieChart } from '@cloudscape-design/components';

export type StanceDonutProps = {
  total: number;
  totalAccept: number;
  totalReject: number;
  max_date: Date;
};

export function StanceDonut(props: StanceDonutProps) {
  return (
    <PieChart
      ariaDescription="Donut chart showing how many tweets have each stance."
      ariaLabel="Donut chart"
      data={[
        {
          title: 'Accept',
          value: props.totalAccept,
          lastUpdate: props.max_date.toISOString().split('T')[0],
        },
        {
          title: 'Reject',
          value: props.totalReject,
          lastUpdate: props.max_date.toISOString().split('T')[0],
        },
      ]}
      detailPopoverContent={(datum, sum) => [
        { key: 'Tweet count', value: datum.value.toLocaleString('en-US') },
        {
          key: 'Percentage',
          value: `${((datum.value / sum) * 100).toFixed(0)}%`,
        },
        { key: 'Last update on', value: datum.lastUpdate },
      ]}
      empty={
        <Box color="inherit" textAlign="center">
          <b>No data available</b>
          <Box color="inherit" variant="p">
            There is no data available
          </Box>
        </Box>
      }
      errorText="Error loading data."
      i18nStrings={{
        detailsValue: 'Value',
        detailsPercentage: 'Percentage',
        filterLabel: 'Filter displayed data',
        filterPlaceholder: 'Filter data',
        filterSelectedAriaLabel: 'selected',
        detailPopoverDismissAriaLabel: 'Dismiss',
        legendAriaLabel: 'Legend',
        chartAriaRoleDescription: 'pie chart',
        segmentAriaRoleDescription: 'segment',
      }}
      innerMetricDescription="Tweets"
      innerMetricValue={`${props.total.toLocaleString('en-US')}`}
      loadingText="Loading chart"
      noMatch={
        <Box color="inherit" textAlign="center">
          <b>No matching data</b>
          <Box color="inherit" variant="p">
            There is no matching data to display
          </Box>
          <Button>Clear filter</Button>
        </Box>
      }
      recoveryText="Retry"
      segmentDescription={(datum, sum) =>
        `${datum.value.toLocaleString('en-US')} tweets,\n ${((datum.value / sum) * 100).toFixed(
          0,
        )}%`
      }
      size="large"
      variant="donut"
      hideFilter
      hideLegend
    />
  );
}
