import {
  AppLayout,
  ContentLayout,
  Header,
  SpaceBetween,
  Container,
  Box,
} from '@cloudscape-design/components';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';

import { LocalNavigation, Breadcrumbs, LocalNavigationProps } from '../../navigation';
import { StanceTimeGraph } from '../stance/time';
import { EmptyState, ErrorState, LoadingState } from '../../common/data/loading';
import { FrameContext } from '../../common/data/context';

import { FrameInfoTable } from './info';

export default function FramePage(props: LocalNavigationProps) {
  const params = useParams();

  const state = useContext(FrameContext).state;

  const frameId = params.frameId ?? '';

  let content = (
    <Container>
      <Box>Frame data not found</Box>
    </Container>
  );
  let header = <Header variant="h1">Unknown COVID-19 Vaccine Hesitancy Frame</Header>;

  if (state.loading) {
    content = <LoadingState />;
    header = <Header variant="h1">Loading...</Header>;
  }

  if (state.error) {
    content = (
      <SpaceBetween direction="vertical" size="xxs">
        <EmptyState />
        <ErrorState error={state.error} onRetry={state.retry} />
      </SpaceBetween>
    );
    header = <Header variant="h1">Error</Header>;
  }

  if (frameId !== undefined && state.value !== undefined) {
    const info = state.value?.frameLookup[frameId];

    if (info !== undefined) {
      header = (
        <SpaceBetween direction="vertical" size="xxs">
          <Header variant="h1">COVID-19 Vaccine Hesitancy Framing {params.frameId}</Header>
          <Header variant="h2">{info.frame.text}</Header>
        </SpaceBetween>
      );
      content = (
        <SpaceBetween direction="vertical" size="l">
          <FrameInfoTable info={info} />
          <StanceTimeGraph
            acceptSeries={info.acceptSeries}
            header={<Header variant="h2">Framing Tweets by Stance</Header>}
            rejectSeries={info.rejectSeries}
          />
        </SpaceBetween>
      );
    }
  }

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Frames', href: '/frames' },
            { text: params.frameId ?? '?', href: '' },
          ]}
        />
      }
      content={
        <ContentLayout header={<Container header={header} disableContentPaddings />}>
          {content}
        </ContentLayout>
      }
      contentType="default"
      navigation={<LocalNavigation />}
      navigationOpen={props.navigationOpen}
      navigationWidth={250}
      toolsHide
      onNavigationChange={(event) => props.setNavigationOpen(event.detail.open)}
    />
  );
}
