import { Header, Box, HelpPanel, Icon, Link } from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

export function DiscoveryHelp() {
  const navigate = useNavigate();

  return (
    <HelpPanel
      footer={
        <div>
          <Header variant="h3">
            Learn more <Icon name="external" />
          </Header>
          <ul>
            <li>
              <Link href="https://aclanthology.org/2024.eacl-long.97/" target="_blank" external>
                Discovering and Articulating Frames of Communication from Social Media Using
                Chain-of-Thought Reasoning
              </Link>
            </li>
          </ul>
        </div>
      }
      header={<Header variant="h2">About Framings</Header>}
    >
      <div>
        <Header variant="h3">What is a framing?</Header>
        <Box variant="p">
          Social science stipulates that discourse almost inescapably involves framing — a strategy
          of highlighting certain issues to promote a certain interpretation or attitude. For
          example, when misinformation is used in framing vaccine confidence, it typically results
          in vaccine hesitancy.
        </Box>
        <Header variant="h3">How were framings uncovered?</Header>
        <Box variant="p">
          Framings were discovered and articulated by GPT-4, a state-of-the-art language model.
        </Box>
        <Header variant="h3">How do I explore the graph?</Header>
        <Box variant="p">
          Use your mouse wheel to zoom in and out by scrolling. You can hover over a node to see its
          articulated frame of communication, and similarly hover over an edge to see the rationale
          generated by GPT-4.
        </Box>
      </div>
    </HelpPanel>
  );
}
