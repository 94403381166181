import { AppLayout, ContentLayout } from '@cloudscape-design/components';
import { useParams } from 'react-router-dom';

import { LocalNavigation, Breadcrumbs, LocalNavigationProps } from '../../navigation';
import { useBibTex } from '../../common/bibtex';

export default function BibTexPage(props: LocalNavigationProps) {
  const params = useParams();
  const path = 'https://cdn.maxnlp.ai/' + params.pubId;

  const { header, body } = useBibTex({ path });

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Publications', href: '/publications' },
            { text: params.pubId ?? '?', href: '' },
          ]}
        />
      }
      content={<ContentLayout header={header}>{body}</ContentLayout>}
      contentType="default"
      navigation={<LocalNavigation />}
      navigationOpen={props.navigationOpen}
      navigationWidth={250}
      toolsHide
      onNavigationChange={(event) => props.setNavigationOpen(event.detail.open)}
    />
  );
}
