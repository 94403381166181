import {
  AppLayout,
  ContentLayout,
  Box,
  Button,
  Container,
  Header,
  Link,
  SpaceBetween,
  Grid,
} from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

import { LocalNavigation, Breadcrumbs, LocalNavigationProps } from '../navigation';
import Publications from '../publications/publications';
import { PublicationsList } from '../common/publications';
import Projects from '../projects/projects';
import { ShortProjectsList } from '../projects/short';

export default function Homepage(props: LocalNavigationProps) {
  const navigate = useNavigate();

  return (
    <AppLayout
      breadcrumbs={<Breadcrumbs items={[{ text: 'Home', href: '' }]} />}
      content={
        <ContentLayout
          header={
            <Box
              padding={{
                top: 'm',
                bottom: 'm',
              }}
            >
              <Container
                header={
                  <Header
                    description={
                      <SpaceBetween direction="vertical" size="xxxs">
                        <Box variant="p">Ph.D. Candidate in Computer Science at UT Dallas</Box>
                        <Box variant="p">maxwell.weinzierl@utdallas.edu</Box>
                      </SpaceBetween>
                    }
                    variant="h1"
                  >
                    Max Weinzierl
                  </Header>
                }
                media={{
                  content: <img alt="Profile" src="https://cdn.aiescape.io/max.png" />,
                  position: 'side',
                  width: 'min(25%, 200px)',
                }}
                disableContentPaddings
              />
            </Box>
          }
        >
          <Grid
            gridDefinition={[
              { colspan: { default: 12, s: 6 } },
              { colspan: { default: 12, s: 6 } },
              { colspan: { default: 12, s: 6 } },
              { colspan: { default: 12, s: 6 } },
            ]}
          >
            <Container
              header={<Header variant="h2">About Me</Header>}
              media={{
                content: (
                  <img
                    alt="Abstract art representing my journey"
                    src="https://cdn.maxnlp.ai/images/about.webp"
                  />
                ),
                position: 'side',
                width: '25%',
              }}
            >
              <Box variant="p">
                Hello! My name is Maxwell Weinzierl, but I go by Max! Born in Geneva, Illinois, my
                family moved to Dallas, Texas, where I grew up. I have always been interested in
                computers and technology, and I have been programming since I was 12 years old. My
                academic journey began at UT Dallas, where my goal was to graduate as quickly as
                possible and begin my career in the tech industry. However, through the wonderful
                research professors at UTD I discovered a passion for research and decided to pursue
                a graduate degree after completing my Bachelor of Science in Computer Science in
                December 2018. Continuing my studies at the same institution, I earned a Master of
                Science in Computer Science in May 2020, specializing in Intelligent Systems. I
                embarked on the Ph.D. program at UT Dallas in the fall of 2020 and am on track to
                graduate and defend my dissertation in the spring of 2025.
              </Box>
            </Container>
            <Container
              header={<Header variant="h2">Research Focus</Header>}
              media={{
                content: (
                  <img
                    alt="Abstract art representing my research focus"
                    src="https://cdn.maxnlp.ai/images/research.webp"
                  />
                ),
                position: 'side',
                width: '25%',
              }}
            >
              <Box variant="p">
                I have had the unique opportunity to pursue my Ph.D. during a global pandemic, which
                has significantly influenced my research interests. My research focuses on employing
                Natural Language Processing (NLP) with Artificial Intelligence (AI) on social media
                for public health. More specifically, I research the ways in which social media can
                be used to frame public health issues, such as vaccine hesitancy, and how these
                framings impact public opinion. This research involves utilizing State-of-the-Art
                NLP and AI methods and techniques, such as Machine Learning, Deep Learning, and
                Large Language Models, to analyze large-scale social media datasets. I am also
                interested in how social media can be used to detect and track the spread of
                misinformation, and how images and videos play a key role in framing public health
                issues. I am a member of the{' '}
                <Link href="https://www.hlt.utdallas.edu/" target="_blank" external>
                  Human Language Technology Research Institute
                </Link>
                , and my research advisor is{' '}
                <Link href="https://personal.utdallas.edu/~sanda/" target="_blank" external>
                  Dr. Sanda Harabagiu
                </Link>
                .
              </Box>
            </Container>
            <PublicationsList
              actions={
                <Button
                  variant="normal"
                  onClick={() => {
                    navigate('publications');
                  }}
                >
                  See More
                </Button>
              }
              publications={Publications.slice(0, 3)}
              title="Recent Publications"
              hideAbstracts
              hideYears
              smallFont
            />
            <ShortProjectsList
              actions={
                <Button
                  variant="normal"
                  onClick={() => {
                    navigate('projects');
                  }}
                >
                  See More
                </Button>
              }
              projects={Projects.slice(0, 3)}
              title="Recent Projects"
            />
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      navigation={<LocalNavigation />}
      navigationOpen={props.navigationOpen}
      navigationWidth={250}
      toolsHide
      onNavigationChange={(event) => props.setNavigationOpen(event.detail.open)}
    />
  );
}
