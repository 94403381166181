import { AsyncStateRetry } from 'react-use/lib/useAsyncRetry';

import {
  Theme,
  StanceMeasurement,
  FrameInfo,
  CategoryInfo,
  ThemeInfo,
  MoralFoundationInfo,
  QuestionInfo,
  QuestionThemeInfo,
  QuestionTheme,
  FrameMeasurement,
  Frame,
  Question,
} from './types';
// import { time_data, questions as raw_questions, frames, themes, questionThemes } from './raw';
// export const questions = raw_questions;

export const moralityValues = [
  'Care',
  'Harm',
  'Fairness',
  'Cheating',
  'Loyalty',
  'Betrayal',
  'Authority',
  'Subversion',
  'Purity',
  'Degradation',
];

export const taxonomyValues = ['trust-', 'trust+', 'misinformation', 'literacy', 'civil_rights'];

export const taxNames: { [key: string]: string } = {
  'trust-': 'Trust Erosion',
  'trust+': 'Trust Building',
  misinformation: 'Misinformation',
  literacy: 'Literacy',
  civil_rights: 'Civil Rights',
};

export const mfDescriptions: { [key: string]: string } = {
  Care: 'Care for others, generosity, compassion, ability to feel pain of others, sensitivity to suffering of others, prohibiting actions that harm others.',
  Harm: 'Harm to others, cruelty, lack of compassion, ability to feel pain of others, lack of sensitivity to suffering of others, allowing actions that harm others.',
  Fairness:
    'Fairness, justice, reciprocity, reciprocal altruism, rights, autonomy, equality, proportionality, prohibiting cheating.',
  Cheating:
    'Cheating, injustice, lack of reciprocity, lack of reciprocal altruism, lack of rights, lack of autonomy, inequality, disproportionality, allowing cheating.',
  Loyalty:
    'Group affiliation and solidarity, virtues of patriotism, self-sacrifice for the group, prohibiting betrayal of one’s group.',
  Betrayal:
    'Betrayal of one’s group, lack of group affiliation and solidarity, lack of virtues of patriotism, lack of self-sacrifice for the group, allowing betrayal of one’s group.',
  Authority:
    'Fulfilling social roles, authority, respect for social hierarchy/traditions, leadership, prohibiting rebellion against authority.',
  Subversion:
    'Rebellion against authority, lack of respect for social hierarchy/traditions, lack of leadership, allowing rebellion against authority.',
  Purity:
    'Associations with sacred and holy, disgust, contamination, religious notions which guide how to live, prohibiting violating the sacred.',
  Degradation:
    'Associations with profane and unholy, lack of disgust, lack of contamination, lack of religious notions which guide how to live, allowing violating the sacred.',
};

export const taxDescriptions: { [key: string]: string } = {
  misinformation:
    'False or inaccurate framings about the COVID-19 vaccines, especially framings which are deliberately intended to deceive.',
  'trust-':
    'Framings which undermine trust in the COVID-19 vaccines, but do not necessarily contain misinformation.',
  'trust+':
    'Framings which build trust in the COVID-19 vaccines by instilling confidence and dispelling concerns.',
  literacy:
    'Framings which educate the public about the COVID-19 vaccines, including technical and scientific information about the vaccines.',
  civil_rights:
    'Framings which emphasize the importance of civil rights and liberties, especially in the context of the COVID-19 vaccines.',
};

function addDays(x: Date, y: number) {
  const date = new Date(x.valueOf());
  date.setDate(date.getDate() + y);

  return date;
}

function getDates(start: Date, stop: Date) {
  const dateArray: Date[] = [];
  let currentDate = start;

  while (currentDate <= stop) {
    dateArray.push(new Date(currentDate));
    currentDate = addDays(currentDate, 1);
  }

  return dateArray;
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  if (value === null || value === undefined) return false;

  return true;
}

export type FrameData = {
  frameLookup: { [key: string]: FrameInfo };
  frameInfos: FrameInfo[];
  taxLookup: { [key: string]: CategoryInfo };
  taxInfos: CategoryInfo[];
  themeLookup: { [key: string]: ThemeInfo };
  themeInfos: ThemeInfo[];
  moralLookup: { [key: string]: MoralFoundationInfo };
  moralInfos: MoralFoundationInfo[];
  questionLookup: { [key: string]: QuestionInfo };
  questionInfos: QuestionInfo[];
  questionThemeLookup: { [key: string]: QuestionThemeInfo };
  questionThemeInfos: QuestionThemeInfo[];
  questionThemeTextLookup: { [key: string]: QuestionTheme };
  questions: Question[];
};

export function processData(
  time_data: FrameMeasurement[],
  frames: Frame[],
  themes: Theme[],
  questions: Question[],
  questionThemes: QuestionTheme[],
): FrameData {
  const frame_time_data: { [key: string]: { [key: string]: { [key: string]: number } } } = {};

  const frame_min_date: { [key: string]: Date } = {};
  const frame_max_date: { [key: string]: Date } = {};

  for (const d of time_data) {
    const date = new Date(d.Date);

    if (frame_time_data[d.f_id] === undefined) {
      frame_time_data[d.f_id] = {};
      frame_min_date[d.f_id] = date;
      frame_max_date[d.f_id] = date;
    }
    frame_min_date[d.f_id] = frame_min_date[d.f_id] > date ? date : frame_min_date[d.f_id];
    frame_max_date[d.f_id] = frame_min_date[d.f_id] < date ? date : frame_min_date[d.f_id];
    const fd = frame_time_data[d.f_id];

    if (fd[d.Stance] === undefined) {
      fd[d.Stance] = {};
    }
    fd[d.Stance][d.Date] = d.tweets;
  }

  // fill empty dates for a given stance with zeros for each frame
  for (const [f_id, f_series] of Object.entries(frame_time_data)) {
    if (f_series['Accept'] === undefined) {
      f_series['Accept'] = {};
    }

    if (f_series['Reject'] === undefined) {
      f_series['Reject'] = {};
    }

    for (const [, s_series] of Object.entries(f_series)) {
      for (const d of getDates(frame_min_date[f_id], frame_max_date[f_id])) {
        const d_str = d.toISOString().split('T')[0];

        if (s_series[d_str] === undefined) {
          s_series[d_str] = 0;
        }
      }
    }
  }

  const frame_time_series: { [key: string]: { [key: string]: StanceMeasurement[] } } = {};

  // convert to series for each frame
  for (const [f_id, f_series] of Object.entries(frame_time_data)) {
    frame_time_series[f_id] = {};

    for (const [stance, s_series] of Object.entries(f_series)) {
      frame_time_series[f_id][stance] = [];

      for (const [date_str, tweets] of Object.entries(s_series)) {
        frame_time_series[f_id][stance].push({ x: new Date(date_str), y: tweets });
      }
      frame_time_series[f_id][stance].sort((a, b) => a.x.getTime() - b.x.getTime());
    }
  }

  // TODO return
  const frameLookup: { [key: string]: FrameInfo } = {};
  // TODO return
  const frameInfos: FrameInfo[] = [];

  for (const frame of frames) {
    const frameId = frame.f_id;
    const acceptSeries = frame_time_series[frameId]['Accept'];
    const rejectSeries = frame_time_series[frameId]['Reject'];
    const totalAccept = acceptSeries.reduce((a, b) => a + b.y, 0);
    const totalReject = rejectSeries.reduce((a, b) => a + b.y, 0);
    const total = totalAccept + totalReject;

    const q_id = frame.q_id;
    const moralities = frame.morality;
    const frame_themes = frame.themes
      .map((t) => {
        const theme = themes.find((th) => th.idx === t.theme_idx);

        return theme;
      })
      .filter((t) => t !== undefined) as Theme[];

    const question = questions.find((q) => q.q_id === q_id)!;
    const max_date = frame_max_date[frameId]!;
    const min_date = frame_min_date[frameId]!;
    const taxonomies = frame_themes
      .map((t) => t.taxonomy)
      .filter((value, index, array) => array.indexOf(value) === index);
    const info: FrameInfo = {
      frame,
      totalAccept,
      totalReject,
      total,
      question,
      moralities,
      themes: frame_themes,
      acceptSeries,
      rejectSeries,
      max_date,
      min_date,
      taxonomies,
    };
    frameInfos.push(info);
    frameLookup[frameId] = info;
  }

  // TODO return
  const taxLookup: { [key: string]: CategoryInfo } = {};
  // TODO return
  const taxInfos: CategoryInfo[] = [];

  // create category lookup and info
  for (const tax of taxonomyValues) {
    const taxFrames = frameInfos.filter((f) => f.taxonomies.includes(tax));
    const totalAccept = taxFrames.reduce((a, b) => a + b.totalAccept, 0);
    const totalReject = taxFrames.reduce((a, b) => a + b.totalReject, 0);
    const total = totalAccept + totalReject;

    const taxThemes = themes.filter((t) => t.taxonomy === tax);

    const acceptCount: { [key: string]: number } = {};
    const rejectCount: { [key: string]: number } = {};

    let min_date: Date = taxFrames[0].min_date;
    let max_date: Date = taxFrames[0].max_date;
    const themeAcceptCount: { [key: number]: number } = {};
    const themeRejectCount: { [key: number]: number } = {};
    const mfAcceptCount: { [key: string]: number } = {};
    const mfRejectCount: { [key: string]: number } = {};

    for (const f of taxFrames) {
      for (const s of f.acceptSeries) {
        const date = s.x;
        min_date = min_date > date ? date : min_date;
        max_date = max_date < date ? date : max_date;

        const d_str = s.x.toISOString().split('T')[0];

        if (acceptCount[d_str] === undefined) {
          acceptCount[d_str] = 0;
        }
        acceptCount[d_str] += s.y;

        for (const t of f.themes) {
          if (themeAcceptCount[t.idx] === undefined) {
            themeAcceptCount[t.idx] = 0;
          }
          themeAcceptCount[t.idx] += s.y;
        }

        for (const t of f.moralities) {
          if (mfAcceptCount[t] === undefined) {
            mfAcceptCount[t] = 0;
          }
          mfAcceptCount[t] += s.y;
        }
      }

      for (const s of f.rejectSeries) {
        const d_str = s.x.toISOString().split('T')[0];

        if (rejectCount[d_str] === undefined) {
          rejectCount[d_str] = 0;
        }
        rejectCount[d_str] += s.y;

        for (const t of f.themes) {
          if (themeRejectCount[t.idx] === undefined) {
            themeRejectCount[t.idx] = 0;
          }
          themeRejectCount[t.idx] += s.y;
        }

        for (const t of f.moralities) {
          if (mfRejectCount[t] === undefined) {
            mfRejectCount[t] = 0;
          }
          mfRejectCount[t] += s.y;
        }
      }
    }

    for (const d of getDates(min_date, max_date)) {
      const d_str = d.toISOString().split('T')[0];

      if (acceptCount[d_str] === undefined) {
        acceptCount[d_str] = 0;
      }

      if (rejectCount[d_str] === undefined) {
        rejectCount[d_str] = 0;
      }
    }

    const acceptSeries: StanceMeasurement[] = [];

    for (const [date_str, tweets] of Object.entries(acceptCount)) {
      acceptSeries.push({ x: new Date(date_str), y: tweets });
    }
    acceptSeries.sort((a, b) => a.x.getTime() - b.x.getTime());

    const rejectSeries: StanceMeasurement[] = [];

    for (const [date_str, tweets] of Object.entries(rejectCount)) {
      rejectSeries.push({ x: new Date(date_str), y: tweets });
    }
    rejectSeries.sort((a, b) => a.x.getTime() - b.x.getTime());

    const themeStance = taxThemes.map((t) => {
      return {
        theme: t,
        total: themeAcceptCount[t.idx] + themeRejectCount[t.idx],
        accept: themeAcceptCount[t.idx],
        reject: themeRejectCount[t.idx],
      };
    });
    themeStance.sort((a, b) => b.total - a.total);

    const mfStance = moralityValues
      .map((mf) => {
        if (mfAcceptCount[mf] === undefined || mfRejectCount[mf] === undefined) {
          return null;
        }

        return {
          mf,
          description: mfDescriptions[mf],
          total: mfAcceptCount[mf] + mfRejectCount[mf],
          accept: mfAcceptCount[mf],
          reject: mfRejectCount[mf],
        };
      })
      .filter(notEmpty);
    mfStance.sort((a, b) => b.total - a.total);

    const taxInfo: CategoryInfo = {
      name: tax,
      displayName: taxNames[tax],
      description: taxDescriptions[tax],
      frames: taxFrames,
      totalAccept,
      totalReject,
      total,
      themes: taxThemes,
      themeStance,
      mfStance,
      acceptSeries,
      rejectSeries,
      min_date,
      max_date,
    };
    taxInfos.push(taxInfo);
    taxLookup[tax] = taxInfo;
  }

  // TODO return
  // create theme lookup and info
  const themeLookup: { [key: string]: ThemeInfo } = {};
  // TODO return
  const themeInfos: ThemeInfo[] = [];

  for (const theme of themes) {
    const themeFrames = frameInfos.filter((f) => f.themes.map((t) => t.idx).includes(theme.idx));
    const totalAccept = themeFrames.reduce((a, b) => a + b.totalAccept, 0);
    const totalReject = themeFrames.reduce((a, b) => a + b.totalReject, 0);
    const total = totalAccept + totalReject;

    const acceptCount: { [key: string]: number } = {};
    const rejectCount: { [key: string]: number } = {};

    const min_date: Date = themeFrames[0].min_date;
    const max_date: Date = themeFrames[0].max_date;
    const themeAcceptCount: { [key: number]: number } = {};
    const themeRejectCount: { [key: number]: number } = {};
    const mfAcceptCount: { [key: string]: number } = {};
    const mfRejectCount: { [key: string]: number } = {};

    for (const f of themeFrames) {
      for (const s of f.acceptSeries) {
        const d_str = s.x.toISOString().split('T')[0];

        if (acceptCount[d_str] === undefined) {
          acceptCount[d_str] = 0;
        }
        acceptCount[d_str] += s.y;

        for (const t of f.themes) {
          if (themeAcceptCount[t.idx] === undefined) {
            themeAcceptCount[t.idx] = 0;
          }
          themeAcceptCount[t.idx] += s.y;
        }

        for (const t of f.moralities) {
          if (mfAcceptCount[t] === undefined) {
            mfAcceptCount[t] = 0;
          }
          mfAcceptCount[t] += s.y;
        }
      }

      for (const s of f.rejectSeries) {
        const d_str = s.x.toISOString().split('T')[0];

        if (rejectCount[d_str] === undefined) {
          rejectCount[d_str] = 0;
        }
        rejectCount[d_str] += s.y;

        for (const t of f.themes) {
          if (themeRejectCount[t.idx] === undefined) {
            themeRejectCount[t.idx] = 0;
          }
          themeRejectCount[t.idx] += s.y;
        }

        for (const t of f.moralities) {
          if (mfRejectCount[t] === undefined) {
            mfRejectCount[t] = 0;
          }
          mfRejectCount[t] += s.y;
        }
      }
    }

    for (const d of getDates(min_date, max_date)) {
      const d_str = d.toISOString().split('T')[0];

      if (acceptCount[d_str] === undefined) {
        acceptCount[d_str] = 0;
      }

      if (rejectCount[d_str] === undefined) {
        rejectCount[d_str] = 0;
      }
    }

    const acceptSeries: StanceMeasurement[] = [];

    for (const [date_str, tweets] of Object.entries(acceptCount)) {
      acceptSeries.push({ x: new Date(date_str), y: tweets });
    }
    acceptSeries.sort((a, b) => a.x.getTime() - b.x.getTime());

    const rejectSeries: StanceMeasurement[] = [];

    for (const [date_str, tweets] of Object.entries(rejectCount)) {
      rejectSeries.push({ x: new Date(date_str), y: tweets });
    }
    rejectSeries.sort((a, b) => a.x.getTime() - b.x.getTime());

    const mfStance = moralityValues
      .map((mf) => {
        if (mfAcceptCount[mf] === undefined || mfRejectCount[mf] === undefined) {
          return null;
        }

        return {
          mf,
          description: mfDescriptions[mf],
          total: mfAcceptCount[mf] + mfRejectCount[mf],
          accept: mfAcceptCount[mf],
          reject: mfRejectCount[mf],
        };
      })
      .filter(notEmpty);
    mfStance.sort((a, b) => b.total - a.total);

    const themeInfo: ThemeInfo = {
      theme,
      frames: themeFrames,
      totalAccept,
      totalReject,
      total,
      mfStance,
      acceptSeries,
      rejectSeries,
      min_date,
      max_date,
    };
    themeInfos.push(themeInfo);
    themeLookup[`${theme.idx}`] = themeInfo;
  }

  // TODO return
  // create moral foundation lookup and info
  const moralLookup: { [key: string]: MoralFoundationInfo } = {};
  const moralInfos: MoralFoundationInfo[] = [];

  for (const mf of moralityValues) {
    const mfFrames = frameInfos.filter((f) => f.moralities.includes(mf));
    const totalAccept = mfFrames.reduce((a, b) => a + b.totalAccept, 0);
    const totalReject = mfFrames.reduce((a, b) => a + b.totalReject, 0);
    const total = totalAccept + totalReject;

    const acceptCount: { [key: string]: number } = {};
    const rejectCount: { [key: string]: number } = {};

    let min_date: Date = mfFrames[0].min_date;
    let max_date: Date = mfFrames[0].max_date;
    const taxAcceptCount: { [key: string]: number } = {};
    const taxRejectCount: { [key: string]: number } = {};

    for (const f of mfFrames) {
      for (const s of f.acceptSeries) {
        const date = s.x;
        min_date = min_date > date ? date : min_date;
        max_date = max_date < date ? date : max_date;

        const d_str = s.x.toISOString().split('T')[0];

        if (acceptCount[d_str] === undefined) {
          acceptCount[d_str] = 0;
        }
        acceptCount[d_str] += s.y;

        for (const t of f.themes) {
          if (taxAcceptCount[t.taxonomy] === undefined) {
            taxAcceptCount[t.taxonomy] = 0;
          }
          taxAcceptCount[t.taxonomy] += s.y;
        }
      }

      for (const s of f.rejectSeries) {
        const d_str = s.x.toISOString().split('T')[0];

        if (rejectCount[d_str] === undefined) {
          rejectCount[d_str] = 0;
        }
        rejectCount[d_str] += s.y;

        for (const t of f.themes) {
          if (taxRejectCount[t.taxonomy] === undefined) {
            taxRejectCount[t.taxonomy] = 0;
          }
          taxRejectCount[t.taxonomy] += s.y;
        }
      }
    }

    for (const d of getDates(min_date, max_date)) {
      const d_str = d.toISOString().split('T')[0];

      if (acceptCount[d_str] === undefined) {
        acceptCount[d_str] = 0;
      }

      if (rejectCount[d_str] === undefined) {
        rejectCount[d_str] = 0;
      }
    }

    const acceptSeries: StanceMeasurement[] = [];

    for (const [date_str, tweets] of Object.entries(acceptCount)) {
      acceptSeries.push({ x: new Date(date_str), y: tweets });
    }
    acceptSeries.sort((a, b) => a.x.getTime() - b.x.getTime());

    const rejectSeries: StanceMeasurement[] = [];

    for (const [date_str, tweets] of Object.entries(rejectCount)) {
      rejectSeries.push({ x: new Date(date_str), y: tweets });
    }
    rejectSeries.sort((a, b) => a.x.getTime() - b.x.getTime());

    const taxStance = taxonomyValues
      .map((t) => {
        if (taxAcceptCount[t] === undefined || taxRejectCount[t] === undefined) {
          return null;
        }

        return {
          tax: t,
          description: taxDescriptions[t],
          total: taxAcceptCount[t] + taxRejectCount[t],
          accept: taxAcceptCount[t],
          reject: taxRejectCount[t],
        };
      })
      .filter(notEmpty);
    taxStance.sort((a, b) => b.total - a.total);

    const mfInfo: MoralFoundationInfo = {
      name: mf,
      description: mfDescriptions[mf],
      frames: mfFrames,
      totalAccept,
      totalReject,
      total,
      taxStance,
      acceptSeries,
      rejectSeries,
      min_date,
      max_date,
    };
    moralInfos.push(mfInfo);
    moralLookup[mf] = mfInfo;
  }

  // TODO return
  // create question lookup and info
  const questionLookup: { [key: string]: QuestionInfo } = {};
  // TODO return
  const questionInfos: QuestionInfo[] = [];

  for (const q of questions) {
    const qFrames = frameInfos.filter((f) => f.question.q_id === q.q_id);
    const totalAccept = qFrames.reduce((a, b) => a + b.totalAccept, 0);
    const totalReject = qFrames.reduce((a, b) => a + b.totalReject, 0);
    const total = totalAccept + totalReject;

    const acceptCount: { [key: string]: number } = {};
    const rejectCount: { [key: string]: number } = {};

    let min_date: Date = qFrames[0].min_date;
    let max_date: Date = qFrames[0].max_date;
    const taxAcceptCount: { [key: string]: number } = {};
    const taxRejectCount: { [key: string]: number } = {};
    const mfAcceptCount: { [key: string]: number } = {};
    const mfRejectCount: { [key: string]: number } = {};

    for (const f of qFrames) {
      for (const s of f.acceptSeries) {
        const date = s.x;
        min_date = min_date > date ? date : min_date;
        max_date = max_date < date ? date : max_date;

        const d_str = s.x.toISOString().split('T')[0];

        if (acceptCount[d_str] === undefined) {
          acceptCount[d_str] = 0;
        }
        acceptCount[d_str] += s.y;

        for (const t of f.themes) {
          if (taxAcceptCount[t.taxonomy] === undefined) {
            taxAcceptCount[t.taxonomy] = 0;
          }
          taxAcceptCount[t.taxonomy] += s.y;
        }

        for (const m of f.moralities) {
          if (mfAcceptCount[m] === undefined) {
            mfAcceptCount[m] = 0;
          }
          mfAcceptCount[m] += s.y;
        }
      }

      for (const s of f.rejectSeries) {
        const d_str = s.x.toISOString().split('T')[0];

        if (rejectCount[d_str] === undefined) {
          rejectCount[d_str] = 0;
        }
        rejectCount[d_str] += s.y;

        for (const t of f.themes) {
          if (taxRejectCount[t.taxonomy] === undefined) {
            taxRejectCount[t.taxonomy] = 0;
          }
          taxRejectCount[t.taxonomy] += s.y;
        }

        for (const m of f.moralities) {
          if (mfRejectCount[m] === undefined) {
            mfRejectCount[m] = 0;
          }
          mfRejectCount[m] += s.y;
        }
      }
    }

    for (const d of getDates(min_date, max_date)) {
      const d_str = d.toISOString().split('T')[0];

      if (acceptCount[d_str] === undefined) {
        acceptCount[d_str] = 0;
      }

      if (rejectCount[d_str] === undefined) {
        rejectCount[d_str] = 0;
      }
    }

    const acceptSeries: StanceMeasurement[] = [];

    for (const [date_str, tweets] of Object.entries(acceptCount)) {
      acceptSeries.push({ x: new Date(date_str), y: tweets });
    }
    acceptSeries.sort((a, b) => a.x.getTime() - b.x.getTime());

    const rejectSeries: StanceMeasurement[] = [];

    for (const [date_str, tweets] of Object.entries(rejectCount)) {
      rejectSeries.push({ x: new Date(date_str), y: tweets });
    }
    rejectSeries.sort((a, b) => a.x.getTime() - b.x.getTime());

    const taxStance = taxonomyValues
      .map((t) => {
        if (taxAcceptCount[t] === undefined || taxRejectCount[t] === undefined) {
          return null;
        }

        return {
          tax: t,
          description: taxDescriptions[t],
          total: taxAcceptCount[t] + taxRejectCount[t],
          accept: taxAcceptCount[t],
          reject: taxRejectCount[t],
        };
      })
      .filter(notEmpty);
    taxStance.sort((a, b) => b.total - a.total);

    const mfStance = moralityValues
      .map((m) => {
        if (mfAcceptCount[m] === undefined || mfRejectCount[m] === undefined) {
          return null;
        }

        return {
          mf: m,
          description: mfDescriptions[m],
          total: mfAcceptCount[m] + mfRejectCount[m],
          accept: mfAcceptCount[m],
          reject: mfRejectCount[m],
        };
      })
      .filter(notEmpty);
    mfStance.sort((a, b) => b.total - a.total);

    const qInfo: QuestionInfo = {
      question: q,
      frames: qFrames,
      totalAccept,
      totalReject,
      total,
      taxStance,
      mfStance,
      acceptSeries,
      rejectSeries,
      min_date,
      max_date,
    };
    questionInfos.push(qInfo);
    questionLookup[q.q_id] = qInfo;
  }

  // TODO return
  // create question theme lookup and info
  const questionThemeLookup: { [key: string]: QuestionThemeInfo } = {};
  // TODO return
  const questionThemeInfos: QuestionThemeInfo[] = [];
  // TODO return
  const questionThemeTextLookup: { [key: string]: QuestionTheme } = {};

  for (const qt of questionThemes) {
    questionThemeTextLookup[qt.text] = qt;
    const qtFrames = frameInfos.filter((f) => f.question.q_theme === qt.text);
    const totalAccept = qtFrames.reduce((a, b) => a + b.totalAccept, 0);
    const totalReject = qtFrames.reduce((a, b) => a + b.totalReject, 0);
    const total = totalAccept + totalReject;

    const acceptCount: { [key: string]: number } = {};
    const rejectCount: { [key: string]: number } = {};

    let min_date: Date = qtFrames[0].min_date;
    let max_date: Date = qtFrames[0].max_date;
    const taxAcceptCount: { [key: string]: number } = {};
    const taxRejectCount: { [key: string]: number } = {};
    const mfAcceptCount: { [key: string]: number } = {};
    const mfRejectCount: { [key: string]: number } = {};

    for (const f of qtFrames) {
      for (const s of f.acceptSeries) {
        const date = s.x;
        min_date = min_date > date ? date : min_date;
        max_date = max_date < date ? date : max_date;

        const d_str = s.x.toISOString().split('T')[0];

        if (acceptCount[d_str] === undefined) {
          acceptCount[d_str] = 0;
        }
        acceptCount[d_str] += s.y;

        for (const t of f.themes) {
          if (taxAcceptCount[t.taxonomy] === undefined) {
            taxAcceptCount[t.taxonomy] = 0;
          }
          taxAcceptCount[t.taxonomy] += s.y;
        }

        for (const m of f.moralities) {
          if (mfAcceptCount[m] === undefined) {
            mfAcceptCount[m] = 0;
          }
          mfAcceptCount[m] += s.y;
        }
      }

      for (const s of f.rejectSeries) {
        const d_str = s.x.toISOString().split('T')[0];

        if (rejectCount[d_str] === undefined) {
          rejectCount[d_str] = 0;
        }
        rejectCount[d_str] += s.y;

        for (const t of f.themes) {
          if (taxRejectCount[t.taxonomy] === undefined) {
            taxRejectCount[t.taxonomy] = 0;
          }
          taxRejectCount[t.taxonomy] += s.y;
        }

        for (const t of f.moralities) {
          if (mfRejectCount[t] === undefined) {
            mfRejectCount[t] = 0;
          }
          mfRejectCount[t] += s.y;
        }
      }
    }

    for (const d of getDates(min_date, max_date)) {
      const d_str = d.toISOString().split('T')[0];

      if (acceptCount[d_str] === undefined) {
        acceptCount[d_str] = 0;
      }

      if (rejectCount[d_str] === undefined) {
        rejectCount[d_str] = 0;
      }
    }

    const acceptSeries: StanceMeasurement[] = [];

    for (const [date_str, tweets] of Object.entries(acceptCount)) {
      acceptSeries.push({ x: new Date(date_str), y: tweets });
    }
    acceptSeries.sort((a, b) => a.x.getTime() - b.x.getTime());

    const rejectSeries: StanceMeasurement[] = [];

    for (const [date_str, tweets] of Object.entries(rejectCount)) {
      rejectSeries.push({ x: new Date(date_str), y: tweets });
    }
    rejectSeries.sort((a, b) => a.x.getTime() - b.x.getTime());

    const taxStance = taxonomyValues
      .map((t) => {
        if (taxAcceptCount[t] === undefined || taxRejectCount[t] === undefined) {
          return null;
        }

        return {
          tax: t,
          description: taxDescriptions[t],
          total: taxAcceptCount[t] + taxRejectCount[t],
          accept: taxAcceptCount[t],
          reject: taxRejectCount[t],
        };
      })
      .filter(notEmpty);
    taxStance.sort((a, b) => b.total - a.total);

    const mfStance = moralityValues
      .map((m) => {
        if (mfAcceptCount[m] === undefined || mfRejectCount[m] === undefined) {
          return null;
        }

        return {
          mf: m,
          description: mfDescriptions[m],
          total: mfAcceptCount[m] + mfRejectCount[m],
          accept: mfAcceptCount[m],
          reject: mfRejectCount[m],
        };
      })
      .filter(notEmpty);
    mfStance.sort((a, b) => b.total - a.total);

    const qtInfo: QuestionThemeInfo = {
      theme: qt,
      frames: qtFrames,
      totalAccept,
      totalReject,
      total,
      taxStance,
      mfStance,
      acceptSeries,
      rejectSeries,
      min_date,
      max_date,
    };
    questionThemeInfos.push(qtInfo);
    questionThemeLookup[qt.theme_id] = qtInfo;
  }

  return {
    frameLookup,
    frameInfos,
    taxLookup,
    taxInfos,
    themeLookup,
    themeInfos,
    moralLookup,
    moralInfos,
    questionLookup,
    questionInfos,
    questionThemeLookup,
    questionThemeInfos,
    questionThemeTextLookup,
    questions,
  };
}
