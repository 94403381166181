import { Box, Link } from '@cloudscape-design/components';
import {
  Macro,
  String as StringNode,
  InlineMath,
  Group,
  Node,
  Environment,
} from '@unified-latex/unified-latex-types';
import TeX from '@matejmazur/react-katex';

import { ContentSections } from './content';

const macroEscapes = new Set(['%', '#', '{', '}', '_', '\\']);

export function visitNodeChildren(children: Node[], root: ContentSections) {
  const content: React.ReactNode[] = [];

  for (const child of children) {
    visitNode(child, content, root);
  }

  return content;
}

export function visitNode(node: Node, content: React.ReactNode[], root: ContentSections) {
  if (node.type === 'group') {
    const group = node as Group;

    if (group.content[0].type !== 'macro' && root.openCitation) {
      const citationRefs = getCommaSep(group.content);

      // (same as cite but args are missing, need to be retrieved from prior block)
      if (citationRefs.length > 0) {
        if (root.openCitation === 'citep') {
          pushCite(citationRefs, content, root);
        } else {
          // TODO consider citet differently with author names and year
          pushCite(citationRefs, content, root);
        }
      }
      root.openCitation = undefined;
    } else {
      visitGroup(group, content, root);
    }
  } else if (node.type === 'whitespace' || node.type === 'parbreak') {
    if (content.length > 0 && content[content.length - 1] !== ' ') {
      content.push(' ');
    }
  } else if (node.type === 'string') {
    const string = node as StringNode;

    if (string.content === '~') {
      content.push('\xa0');
    } else {
      content.push(string.content);
    }
  } else if (node.type === 'inlinemath') {
    const inlineMath = node as InlineMath;
    const currentMath: React.ReactNode[] = [];

    for (const child of inlineMath.content) {
      visitMathNode(child, currentMath, root);
    }
    content.push(<TeX key={`node-${++root.nodeId}`} math={currentMath.join('')} />);
  } else if (node.type === 'comment') {
    // ignore comments
  } else if (node.type === 'macro') {
    visitMacro(node as Macro, content, root);
  } else {
    console.error('UNKNOWN NODE', node);
  }
}

export function getCommaSep(children: Node[], joinSep = '') {
  const citationRefs: string[] = [];
  let currentRef: string[] = [];

  for (const child of children) {
    if (child.type === 'string') {
      const string = (child as StringNode).content;

      if (string === ',' && currentRef.length > 0) {
        citationRefs.push(currentRef.join(joinSep));
        currentRef = [];
      } else if (string !== ',') {
        currentRef.push(string);
      }
    }
  }

  if (currentRef.length > 0) {
    citationRefs.push(currentRef.join(joinSep));
    currentRef = [];
  }

  return citationRefs;
}

export function visitGroupChild(child: Node, current: React.ReactNode[], root: ContentSections) {
  if (child.type === 'string') {
    const string = child as StringNode;

    if (string.content === '~') {
      current.push('\xa0');
    } else {
      current.push(string.content);
    }
  } else if (child.type === 'whitespace') {
    if (current.length > 0 && current[current.length - 1] !== ' ') {
      current.push(' ');
    }
  } else if (child.type === 'group') {
    const groupChild = child as Group;
    visitGroup(groupChild, current, root);
  } else if (child.type === 'macro') {
    const macro = child as Macro;
    visitMacro(macro, current, root);
  } else if (child.type === 'parbreak') {
    // ignore parbreak, might need for some other weird groups
  } else if (child.type === 'inlinemath') {
    const inlineMath = child as InlineMath;
    const currentMath: React.ReactNode[] = [];

    for (const c of inlineMath.content) {
      visitMathNode(c, currentMath, root);
    }
    current.push(<TeX key={`node-${++root.nodeId}`} math={currentMath.join('')} />);
  } else {
    console.error('UNKNOWN CHILD', child);
  }
}

export function visitMacroGroup(
  macro: Macro,
  current: React.ReactNode[],
  group: React.ReactNode[],
  root: ContentSections,
) {
  if (macro.content === 'bf' || macro.content === 'textbf') {
    current.push(
      <Box children={group} key={`node-${++root.nodeId}`} fontWeight="bold" variant="span" />,
    );
  } else if (macro.content === 'em' || macro.content === 'emph' || macro.content === 'textit') {
    current.push(
      <Box
        children={group}
        key={`node-${++root.nodeId}`}
        className="emphasized-text"
        variant="span"
      />,
    );
  } else if (macro.content === 'sc' || macro.content === 'textsc') {
    current.push(
      <Box
        children={group}
        key={`node-${++root.nodeId}`}
        className="small-caps-text"
        variant="span"
      />,
    );
  } else if (macro.content === 'underline') {
    current.push(
      <Box
        children={group}
        key={`node-${++root.nodeId}`}
        className="underlined-text"
        variant="span"
      />,
    );
  } else if (macro.content === 'url') {
    const url = macro.args![0].content.map((n) => (n as StringNode).content).join('');
    current.push(
      <Link key={`link-${++root.nodeId}`} href={url} target="_blank" variant="primary" external>
        {url}
      </Link>,
    );
  } else {
    // more macros
    console.error('UNKNOWN GROUP START', macro);
  }
}

export type CitationProps = {
  readonly reference: string;
  readonly citationNumber?: { [key: string]: string };
  readonly linksCount: number;
  readonly refsCount: number;
  readonly nodeId: number;
};

export function Citation(props: CitationProps) {
  let citationNumber = '*';

  if (props.citationNumber) {
    if (props.reference in props.citationNumber) {
      citationNumber = props.citationNumber[props.reference];
    } else {
      console.error('Missing citation number: ', props.reference);
    }
  }

  let refLinkText = `${citationNumber}`;

  if (props.linksCount === 0) {
    refLinkText = '[' + refLinkText;
  }

  if (props.linksCount === props.refsCount - 1) {
    refLinkText = refLinkText + ']';
  }

  if (props.linksCount !== props.refsCount - 1) {
    refLinkText = refLinkText + ',';
  }

  if (props.linksCount > 0 && props.linksCount) {
    refLinkText = ' ' + refLinkText;
  }

  // TODO add hover effect with citation info
  const refLink = (
    <Link
      key={`ref-${props.reference}-${props.nodeId}`}
      variant="primary"
      onFollow={() => {
        const refNode = document.getElementById(`citation-${props.reference}`);
        refNode?.scrollIntoView({
          block: 'center',
        });
      }}
    >
      {refLinkText}
    </Link>
  );

  return refLink;
}

export function pushCite(
  citationRefs: string[],
  content: React.ReactNode[],
  root: ContentSections,
) {
  const links: React.ReactNode[] = [];

  // TODO no need for this if ref keys are well-defined, can do earlier.
  for (const ref of citationRefs.map((ref) => ref.trim().toLowerCase())) {
    if (!(ref in root.publications)) {
      console.error('Missing publication: ', ref);
    } else {
      const citation = root.publications[ref];

      if (!(ref in root.citationNumber)) {
        root.citationNumber[ref] = `${root.citations.length + 1}`;
        root.citations.push(citation);
      }
    }

    links.push(
      <Citation
        key={`cite-${ref}-${++root.nodeId}`}
        citationNumber={root.citationNumber}
        linksCount={links.length}
        nodeId={++root.nodeId}
        reference={ref}
        refsCount={citationRefs.length}
      />,
    );
  }
  content.push(links);
}

export type ReferenceProps = {
  readonly reference: string;
  readonly citationNumber?: { [key: string]: string };
  readonly children: React.ReactNode[];
  readonly nodeId: number;
};
export function Reference(props: ReferenceProps) {
  let citationNumber = '*';

  if (props.citationNumber) {
    if (props.reference in props.citationNumber) {
      citationNumber = props.citationNumber[props.reference];
    } else {
      console.error('Missing reference number: ', props.reference);
    }
  }

  return (
    <Link
      children={[...props.children, citationNumber]}
      key={`ref-${props.reference}-${props.nodeId}`}
      variant="primary"
      onFollow={() => {
        const refNode = document.getElementById(props.reference);
        refNode?.scrollIntoView({
          block: 'center',
        });
      }}
    />
  );
}

export function pushRef(ref: string, current: React.ReactNode[], root: ContentSections) {
  const removeCount = 2;
  const refItems: React.ReactNode[] = [];

  for (let idx = 0; idx < removeCount; idx++) {
    refItems.push(current.pop());
  }

  current.push(
    <Reference
      children={refItems.reverse()}
      key={`reference-${ref}-${++root.nodeId}`}
      citationNumber={root.citationNumber}
      nodeId={++root.nodeId}
      reference={ref}
    />,
  );
}

export function visitMacro(macro: Macro, current: React.ReactNode[], root: ContentSections) {
  if (macroEscapes.has(macro.content)) {
    current.push(macro.content);
  } else if (macro.content === 'href') {
    const url = macro.args![1].content.map((n) => (n as StringNode).content).join('');
    const display = macro
      .args![macro.args!.length - 1].content.map((n) => (n as StringNode).content)
      .join('');
    current.push(
      <Link key={`link-${++root.nodeId}`} href={url} target="_blank" variant="primary" external>
        {display}
      </Link>,
    );
  } else if (macro.content === 'ref') {
    const labelContent = macro.args![macro.args!.length - 1].content;
    const labelRef = labelContent
      .map((x) => x as StringNode)
      .map((x) => x.content)
      .join('');
    pushRef(labelRef, current, root);
  } else if (macro.content === 'cite') {
    // track citations
    const citationContent = macro.args![macro.args!.length - 1].content;
    const citationRefs = getCommaSep(citationContent);

    if (citationRefs.length > 0) {
      pushCite(citationRefs, current, root);
    }
  } else if (macro.content === 'citet' || macro.content === 'citep') {
    root.openCitation = macro.content;
  } else if (macro.content === 'label') {
    const labelContent = macro.args![macro.args!.length - 1].content;
    const labelRef = labelContent
      .map((x) => x as StringNode)
      .map((x) => x.content)
      .join('');
    root.pushLabel(labelRef);
  } else if (macro.content === 'footnote') {
    // TODO add footnote to container and tracked footnote
    const children = macro.args![macro.args!.length - 1].content;
    const footnoteContent = visitNodeChildren(children, root);
    root.pushFootnote(footnoteContent);
  } else if (macro.content === 'xmark') {
    current.push('×');
  } else if (macro.content === 'cmark') {
    current.push('✓');
  } else if (!macro.args) {
    // TODO if the next thing isn't a group then abandon openMacro.
    root.openMacro = macro;
  } else if (macro.args!.length === 1) {
    const groupElements: React.ReactNode[] = [];
    const children = macro.args![0].content as Node[];

    for (const child of children) {
      visitGroupChild(child, groupElements, root);
    }
    visitMacroGroup(macro, current, groupElements, root);
  } else {
    console.error('UNKNOWN MACRO MULTI ARGS', macro);
  }
}

export function visitGroup(group: Group, current: React.ReactNode[], root: ContentSections) {
  const macro = group.content[0];
  const groupElements: React.ReactNode[] = [];
  const children = group.content.slice(macro.type === 'macro' ? 1 : 0, group.content.length);

  for (const child of children) {
    visitGroupChild(child, groupElements, root);
  }

  if (root.openMacro) {
    visitMacroGroup(root.openMacro, current, groupElements, root);
    root.openMacro = undefined;
  } else if (macro.type === 'macro') {
    visitMacroGroup(macro as Macro, current, groupElements, root);
  } else if (root.openAffiliations) {
    const affiliation = groupElements
      .join('')
      .split('\\')
      .map((s) =>
        s
          .split(',')
          .map((x) => x.trim())
          .join(', '),
      )
      .filter((x) => x);
    root.setAffiliations(affiliation);
    root.openAffiliations = false;
  } else if (root.openAuthor) {
    const authors = groupElements
      .join('')
      .split(',')
      .map((x) => x.trim());
    root.setAuthors(authors);
    root.openAuthor = false;
  } else {
    for (const element of groupElements) {
      current.push(element);
    }
  }
}

export function visitMathNode(node: Node, current: React.ReactNode[], root: ContentSections) {
  if (node.type === 'string') {
    const string = node as StringNode;
    current.push(string.content);
  } else if (node.type === 'whitespace') {
    if (current.length > 0 && current[current.length - 1] !== ' ') {
      current.push(' ');
    }
  } else if (node.type === 'macro') {
    const macro = node as Macro;

    if (macro.content === 'label') {
      // track labeled equations
      const labelContent = macro.args![macro.args!.length - 1].content;
      const labelRef = labelContent
        .map((x) => x as StringNode)
        .map((x) => x.content)
        .join('');
      root.openLabel = labelRef;
    } else if (macro.args) {
      if (macro.escapeToken === '') {
        current.push(`${macro.content}`);
      } else {
        current.push(`\\${macro.content}`);
      }

      for (const arg of macro.args) {
        const argContent: React.ReactNode[] = [];

        for (const argChild of arg.content) {
          visitMathNode(argChild, argContent, root);
        }
        current.push(`${arg.openMark}${argContent.join('')}${arg.closeMark}`);
      }
    } else {
      current.push(`\\${macro.content}`);
    }
  } else if (node.type === 'group') {
    const group = node as Group;
    current.push('{');

    for (const child of group.content) {
      visitMathNode(child, current, root);
    }
    current.push('}');
  } else if (node.type === 'environment' || node.type === 'mathenv') {
    const environment = node as Environment;
    const env = (environment.env as unknown as StringNode)?.content ?? environment.env;
    let envStart = `\\begin{${env}}`;

    if (environment.args) {
      for (const arg of environment.args) {
        envStart += `${arg.openMark}${arg.content.map((x) => (x as StringNode).content).join('')}${
          arg.closeMark
        }`;
      }
    }

    current.push(envStart);

    for (const child of environment.content) {
      visitMathNode(child, current, root);
    }

    current.push(`\\end{${env}}`);
  } else if (node.type === 'inlinemath') {
    const inlineMath = node as InlineMath;
    const currentMath: React.ReactNode[] = [];

    for (const child of inlineMath.content) {
      visitMathNode(child, currentMath, root);
    }
    current.push(`$${currentMath.join('')}$`);
  } else if (node.type === 'parbreak') {
    // ignore paragraph breaks in math mode
  } else {
    console.error('UNKNOWN INLINE MATH CHILD: ', node);
  }
}
