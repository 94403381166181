import { ReactNode } from 'react';
import { Box, Link, SpaceBetween } from '@cloudscape-design/components';

export type Experience = {
  title: string;
  company: string;
  range: string;
  end?: number;
  logo: string;
  location?: string;
  url?: string;
  content?: ReactNode;
};

export const Experiences: Experience[] = [
  {
    title: 'Founder & Chief Executive Officer',
    company: 'AI Escape LLC',
    logo: 'https://cdn.aiescape.io/box.png',
    url: 'https://consulting.aiescape.io/',
    location: 'Dallas, Texas, United States',
    range: 'April 2023 - Present',
    content: (
      <SpaceBetween direction="vertical" size="xs">
        <Box variant="p">
          AI Escape is a revolutionary escape room platform that utilizes advanced artificial
          intelligence to generate dynamic, customized escape room experiences. Check it out!{' '}
          <Link href="https://www.aiescape.io/" target="_blank" external>
            aiescape.io
          </Link>
          . We also offer consulting services for integrating AI into businesses at{' '}
          <Link href="https://consulting.aiescape.io/" target="_blank" external>
            consulting.aiescape.io
          </Link>
          .
        </Box>
      </SpaceBetween>
    ),
  },
  {
    title: 'Personal Tutor',
    company: 'Wyzant - Freelance',
    logo: 'https://cdn.maxnlp.ai/images/wyzant.png',
    url: 'https://www.wyzant.com/Tutors/TutoringToTheMax',
    location: 'Dallas, Texas, United States',
    range: 'August 2022 - Present',
    content: (
      <SpaceBetween direction="vertical" size="xs">
        <Box variant="p">
          My focus is on teaching topics that will help students understand modern Artificial
          Intelligence. That includes Natural Language Processing, Computer Vision, Reinforcement
          Learning, etc.
        </Box>
      </SpaceBetween>
    ),
  },
  {
    title: 'Graduate Teaching Assistant',
    company: 'The University of Texas at Dallas',
    logo: 'https://cdn.maxnlp.ai/images/utd.jpg',
    url: 'https://www.linkedin.com/school/university-of-texas-at-dallas/',
    location: 'Dallas, Texas, United States',
    range: 'January 2019 - Present',
    content: (
      <SpaceBetween direction="vertical" size="xs">
        <Box variant="p">
          Responsibilities include grading graduate homework, providing additional guidance to
          students during office hours, designing interesting class projects, and overseeing and
          organizing exams.
        </Box>
      </SpaceBetween>
    ),
  },
  {
    title: 'Research Scientist Intern',
    company: 'Amazon',
    logo: 'https://cdn.maxnlp.ai/images/alexa.png',
    url: 'https://www.linkedin.com/company/amazon/',
    location: 'Dallas, Texas, United States',
    range: 'May 2023 - August 2023',
    end: 2023,
    content: (
      <SpaceBetween direction="vertical" size="xs">
        <Box variant="p">
          Working on one of Alexa's Natural Language Understanding Teams to discover impactful &
          important customer feedback on social media with Machine Learning.
        </Box>
      </SpaceBetween>
    ),
  },
  {
    title: 'Research Scientist Intern',
    company: 'Amazon',
    logo: 'https://cdn.maxnlp.ai/images/alexa.png',
    url: 'https://www.linkedin.com/company/amazon/',
    location: 'Dallas, Texas, United States',
    range: 'May 2022 - August 2022',
    end: 2022,
    content: (
      <SpaceBetween direction="vertical" size="xs">
        <Box variant="p">
          Working on one of Alexa's Natural Language Understanding Teams to identify customer
          feedback on social media in real-time with Machine Learning.
        </Box>
      </SpaceBetween>
    ),
  },
  {
    title: 'Research Scientist Intern',
    company: 'Amazon',
    logo: 'https://cdn.maxnlp.ai/images/alexa.png',
    url: 'https://www.linkedin.com/company/amazon/',
    location: 'Dallas, Texas, United States',
    range: 'May 2021 - August 2021',
    end: 2021,
    content: (
      <SpaceBetween direction="vertical" size="xs">
        <Box variant="p">
          Worked on one of Alexa's Natural Language Understanding Teams to build Machine Learning
          systems to identify customer feedback on social media.
        </Box>
      </SpaceBetween>
    ),
  },
  {
    title: 'Artificial Intelligence Specialist',
    company: 'Capitalogix',
    logo: 'https://cdn.maxnlp.ai/images/capitalogix.png',
    url: 'https://www.linkedin.com/company/capitalogix/',
    location: 'Dallas, Texas, United States',
    range: 'December 2017 - August 2020',
    end: 2020,
    content: (
      <SpaceBetween direction="vertical" size="xs">
        <Box variant="p">
          Developed Artificial Intelligence models for advanced financial analysis for automatic
          trading on Futures markets.
        </Box>
      </SpaceBetween>
    ),
  },
  {
    title: 'Developer',
    company: 'Capitalogix',
    logo: 'https://cdn.maxnlp.ai/images/capitalogix.png',
    url: 'https://www.linkedin.com/company/capitalogix/',
    location: 'Dallas, Texas, United States',
    range: 'June 2015 - December 2017',
    end: 2017,
    content: (
      <SpaceBetween direction="vertical" size="xs">
        <Box variant="p">
          Developed solutions related to data management, data formatting, quality testing and
          assurance, and general purpose projects.
        </Box>
      </SpaceBetween>
    ),
  },
  {
    title: 'Freelance Developer',
    company: 'Self-Employed',
    logo: 'https://cdn.aiescape.io/max.png',
    location: 'Dallas, Texas, United States',
    range: 'May 2014 - August 2014',
    end: 2014,
    content: (
      <SpaceBetween direction="vertical" size="xs">
        <Box variant="p">
          Various Java projects for multiple clients. Projects included obtaining a specification
          document from the client, developing and testing the Java project, and helping the client
          to deploy the Java project onto their server.
        </Box>
      </SpaceBetween>
    ),
  },
  {
    title: 'Head Developer',
    company: 'Xaldin Edge',
    logo: 'https://cdn.maxnlp.ai/images/xaldin-edge.png',
    location: 'Dallas, Texas, United States',
    range: 'May 2012 - April 2014',
    end: 2014,
    content: (
      <SpaceBetween direction="vertical" size="xs">
        <Box variant="p">
          The head developer at Xaldin Edge. Tasks included creating and developing various Java
          plugins and supporting these plugins over the lifetime of the server. The server had
          around 1000 total users and could hold up to 200 concurrent users. The job centered around
          constant interaction and feedback from the users of the server in order to develop the
          most useful plugins for their needs.
        </Box>
      </SpaceBetween>
    ),
  },
];

export default Experiences;
