import {
  AppLayout,
  ContentLayout,
  Box,
  Container,
  Header,
  Link,
  SpaceBetween,
  Grid,
} from '@cloudscape-design/components';

import { LocalNavigation, Breadcrumbs, LocalNavigationProps } from '../navigation';
import useWindowDimensions from '../common/window';

import Educations, { Education } from './educations';

export type EducationItemProps = {
  education: Education;
  hideContent?: boolean;
};

export function EducationItem(props: EducationItemProps) {
  const { width } = useWindowDimensions();
  const imageContent = (
    <img alt={`Logo of ${props.education.company}`} src={props.education.logo} />
  );

  const image = props.education.url ? (
    <Link href={props.education.url} target="_blank">
      {imageContent}
    </Link>
  ) : (
    imageContent
  );

  return (
    <Container
      media={{
        content: image,
        position: width < 689 ? 'top' : 'side',
        width: '200px',
      }}
    >
      <SpaceBetween direction="vertical" size="xxxs">
        <Box variant="h3">
          <SpaceBetween direction="vertical" size="xxxs">
            {props.education.title}
            <Box variant="h4">{props.education.company}</Box>
          </SpaceBetween>
        </Box>
        <Box variant="small">
          <SpaceBetween direction="vertical" size="xxxs">
            {[props.education.range, props.education.location].filter((x) => x)}
          </SpaceBetween>
        </Box>
        {!props.hideContent ? props.education.content : undefined}
      </SpaceBetween>
    </Container>
  );
}

export type EducationsListProps = {
  title?: string;
  hideContent?: boolean;
  actions?: React.ReactNode;
  educations: Education[];
  hideYears?: boolean;
};

export function EducationsList(props: EducationsListProps) {
  const educations: React.ReactNode[] = [];
  let currentYear: number | undefined | null = null;
  let yearIdx = 0;

  for (const education of props.educations) {
    if (currentYear !== education.end) {
      currentYear = education.end;

      if (!props.hideYears && yearIdx > 0) {
        educations.push(<Header variant="h2">{currentYear}</Header>);
      }
      yearIdx += 1;
    }
    educations.push(<EducationItem education={education} hideContent={props.hideContent} />);
  }

  const content = <SpaceBetween children={educations} direction="vertical" size="s" />;

  return props.title ? (
    <Container
      header={
        <Header actions={props.actions} variant="h2">
          {props.title}
        </Header>
      }
    >
      {content}
    </Container>
  ) : (
    content
  );
}

export default function EducationsPage(props: LocalNavigationProps) {
  return (
    <AppLayout
      breadcrumbs={<Breadcrumbs items={[{ text: 'Educations', href: '' }]} />}
      content={
        <ContentLayout
          header={
            <Container
              header={
                <Header description="My history of education" variant="h1">
                  Education
                </Header>
              }
              disableContentPaddings
            />
          }
        >
          <Grid gridDefinition={[{ colspan: { default: 12 } }]}>
            <EducationsList educations={Educations} />
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      navigation={<LocalNavigation />}
      navigationOpen={props.navigationOpen}
      navigationWidth={250}
      toolsHide
      onNavigationChange={(event) => props.setNavigationOpen(event.detail.open)}
    />
  );
}
