import {
  AppLayout,
  ContentLayout,
  Box,
  Container,
  Header,
  Link,
  SpaceBetween,
  Grid,
} from '@cloudscape-design/components';

import { LocalNavigation, Breadcrumbs, LocalNavigationProps } from '../navigation';
import useWindowDimensions from '../common/window';

import Experiences, { Experience } from './experiences';

export type ExperienceItemProps = {
  experience: Experience;
  hideContent?: boolean;
};

export function ExperienceItem(props: ExperienceItemProps) {
  const { width } = useWindowDimensions();
  const imageContent = (
    <img alt={`Logo of ${props.experience.company}`} src={props.experience.logo} />
  );

  const image = props.experience.url ? (
    <Link href={props.experience.url} target="_blank">
      {imageContent}
    </Link>
  ) : (
    imageContent
  );

  return (
    <Container
      media={{
        content: image,
        position: width < 689 ? 'top' : 'side',
        width: '200px',
      }}
    >
      <SpaceBetween direction="vertical" size="xxxs">
        <Box variant="h3">
          <SpaceBetween direction="vertical" size="xxxs">
            {props.experience.title}
            <Box variant="h4">{props.experience.company}</Box>
          </SpaceBetween>
        </Box>
        <Box variant="small">
          <SpaceBetween direction="vertical" size="xxxs">
            {[props.experience.range, props.experience.location].filter((x) => x)}
          </SpaceBetween>
        </Box>
        {!props.hideContent ? props.experience.content : undefined}
      </SpaceBetween>
    </Container>
  );
}

export type ExperiencesListProps = {
  title?: string;
  hideContent?: boolean;
  actions?: React.ReactNode;
  experiences: Experience[];
  hideYears?: boolean;
};

export function ExperiencesList(props: ExperiencesListProps) {
  const experiences: React.ReactNode[] = [];
  let currentYear: number | undefined | null = null;
  let yearIdx = 0;

  for (const experience of props.experiences) {
    if (currentYear !== experience.end) {
      currentYear = experience.end;

      if (!props.hideYears && yearIdx > 0) {
        experiences.push(<Header variant="h2">{currentYear}</Header>);
      }
      yearIdx += 1;
    }
    experiences.push(<ExperienceItem experience={experience} hideContent={props.hideContent} />);
  }

  const content = <SpaceBetween children={experiences} direction="vertical" size="s" />;

  return props.title ? (
    <Container
      header={
        <Header actions={props.actions} variant="h2">
          {props.title}
        </Header>
      }
    >
      {content}
    </Container>
  ) : (
    content
  );
}

export default function ExperiencesPage(props: LocalNavigationProps) {
  return (
    <AppLayout
      breadcrumbs={<Breadcrumbs items={[{ text: 'Experiences', href: '' }]} />}
      content={
        <ContentLayout
          header={
            <Container
              header={
                <Header description="My work experience" variant="h1">
                  Experience
                </Header>
              }
              disableContentPaddings
            />
          }
        >
          <Grid gridDefinition={[{ colspan: { default: 12 } }]}>
            <ExperiencesList experiences={Experiences} />
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      navigation={<LocalNavigation />}
      navigationOpen={props.navigationOpen}
      navigationWidth={250}
      toolsHide
      onNavigationChange={(event) => props.setNavigationOpen(event.detail.open)}
    />
  );
}
