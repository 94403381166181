import {
  Box,
  Container,
  Header,
  Link,
  Modal,
  SpaceBetween,
  Textarea,
} from '@cloudscape-design/components';
import Grid from '@cloudscape-design/components/grid';
import { EntryFields, normalizeFieldValue } from 'bibtex';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink, faClone, faBook } from '@fortawesome/free-solid-svg-icons';

import useWindowDimensions from '../window';

const localPublications = new Set([
  'weinzierl2020hltri',
  'weinzierl2021automatic',
  'weinzierl2021misinformation',
  'weinzierl2022hesitancy',
  'weinzierl2022identifying',
  'weinzierl2022vaccinelies',
  'weinzierl2023multimodal',
]);

export type Publication = {
  key: string;
  type: string;
  fields: EntryFields;
  bibtex?: string;
};

export type PublicationInfo = {
  itemKey: string;
  title: string;
  authors: string;
  year: number;
  volume?: string;
  pages?: string;
  abstract?: string;
  url?: string;
  source?: string;
  bibtex?: string;
  imageUrl?: string;
  smallFont?: boolean;
};

export type PublicationItemProps = PublicationInfo & {
  itemId?: number;
  hideAbstracts?: boolean;
};

export function PublicationHeader(props: PublicationInfo) {
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);

  const smallFont = props.smallFont ? 'body-s' : 'body-m';

  const content = [
    <Box
      key={`citation-${props.itemKey}-header-content-pub-authors`}
      fontSize={smallFont}
      variant="small"
    >
      {props.authors}
    </Box>,
    <Box
      key={`citation-${props.itemKey}-header-content-pub-source`}
      fontSize={smallFont}
      variant="small"
    >
      {`${props.year} - ` +
        [[props.source, props.volume].filter((x) => x).join(' '), props.pages]
          .filter((x) => x)
          .join(', ')}
    </Box>,
  ];

  if (props.bibtex) {
    content.push(
      <Modal
        key={`citation-${props.itemKey}-bibtex-modal`}
        closeAriaLabel="Close BibTeX"
        header="BibTeX"
        size="medium"
        visible={visible}
        onDismiss={() => setVisible(false)}
      >
        <Textarea
          key={`citation-${props.itemKey}-bibtex-text`}
          rows={25}
          spellcheck={false}
          value={props.bibtex}
        />
      </Modal>,
    );
  }

  return (
    <Header key={`citation-${props.itemKey}-header`} variant="h3">
      <Box key={`citation-${props.itemKey}-header-content`} variant="h4">
        {props.title}
        {props.url || props.bibtex || localPublications.has(props.itemKey) ? (
          <Box key={`citation-${props.itemKey}-header-content-links`} padding="xxs" variant="small">
            <SpaceBetween
              key={`citation-${props.itemKey}-header-content-links-space`}
              direction="horizontal"
              size="xxs"
            >
              {props.url ? (
                <Link
                  key={`citation-${props.itemKey}-header-content-links-url`}
                  href={props.url}
                  target="_blank"
                  variant="info"
                >
                  <FontAwesomeIcon icon={faExternalLink} title="External paper link" />
                </Link>
              ) : undefined}
              {props.bibtex ? (
                <Link
                  key={`citation-${props.itemKey}-header-content-links-bibtex`}
                  variant="info"
                  onFollow={() => setVisible(true)}
                >
                  <FontAwesomeIcon icon={faClone} title="Cite paper" />
                </Link>
              ) : undefined}
              {localPublications.has(props.itemKey) ? (
                <Link
                  key={`citation-${props.itemKey}-header-content-links-full`}
                  href={`/publications/${props.itemKey}`}
                  variant="info"
                  onFollow={(event) => {
                    event.preventDefault();
                    navigate(event.detail.href!);
                  }}
                >
                  <FontAwesomeIcon icon={faBook} title="Read full paper" />
                </Link>
              ) : undefined}
            </SpaceBetween>
          </Box>
        ) : undefined}
      </Box>
      <SpaceBetween
        children={content}
        key={`citation-${props.itemKey}-header-content-pub`}
        direction="vertical"
        size="xxxs"
      />
    </Header>
  );
}

export function PublicationItem(props: PublicationItemProps) {
  const { width } = useWindowDimensions();

  const header = <PublicationHeader {...props} />;

  const content = props.hideAbstracts ? undefined : (
    <Box key={`citation-${props.itemKey}-abstract`} variant="p">
      {props.abstract}
    </Box>
  );

  const title = (
    <Container
      children={content}
      key={`citation-${props.itemKey}`}
      disableContentPaddings={props.hideAbstracts}
      header={
        props.itemId ? (
          <Grid
            key={`citation-${props.itemKey}-header-grid`}
            gridDefinition={[
              { colspan: { default: 2, s: 1 } },
              { colspan: { default: 10, s: 11 } },
            ]}
          >
            <Header key={`citation-${props.itemKey}-id-header`} variant="h3">
              <Box key={`citation-${props.itemKey}-id-value`} variant="h4">
                {props.itemId}
              </Box>
            </Header>
            {header}
          </Grid>
        ) : (
          header
        )
      }
      id={`citation-${props.itemKey}`}
      media={{
        content: props.imageUrl ? (
          <Link href={props.url} target="_blank" variant="info">
            <img alt={props.title} src={props.imageUrl} />
          </Link>
        ) : undefined,
        position: props.smallFont ? (width < 689 ? 'top' : 'side') : 'top',
        width: props.smallFont ? '50%' : undefined,
      }}
    />
  );

  return title;
}

export type PublicationsListProps = {
  title?: string;
  hideAbstracts?: boolean;
  hideYears?: boolean;
  actions?: React.ReactNode;
  publications: Publication[];
  showNumbers?: boolean;
  displayImages?: boolean;
  smallFont?: boolean;
};

export function parsePublication(p: Publication): PublicationInfo {
  const fields = p.fields;
  const pubType = p.type;
  const sharedInfo = {
    itemKey: p.key,
    title: normalizeFieldValue(fields['title']) as string,
    authors: normalizeFieldValue(fields['author']) as string,
    year: normalizeFieldValue(fields['year']) as number,
    pages: normalizeFieldValue(fields['pages']) as string | undefined,
    volume: normalizeFieldValue(fields['volume']) as string | undefined,
    bibtex: p.bibtex,
    abstract: normalizeFieldValue(fields['abstract']) as string | undefined,
    url: normalizeFieldValue(fields['url']) as string | undefined,
    source: normalizeFieldValue(fields[pubType === 'article' ? 'journal' : 'booktitle']) as string,
    imageUrl: `https://cdn.maxnlp.ai/images/${p.key}.png`,
  };

  return sharedInfo;
}

export function PublicationsList(props: PublicationsListProps) {
  const publications: React.ReactNode[] = [];
  let currentYear: number | undefined = undefined;
  let yearIdx = 0;
  let pubId = 1;

  for (const p of props.publications) {
    const pubInfo = parsePublication(p);
    const sharedInfo = {
      itemId: props.showNumbers ? pubId : undefined,
      hideAbstracts: props.hideAbstracts,
      imageUrl: props.displayImages ? pubInfo.imageUrl : undefined,
      smallFont: props.smallFont,
      ...pubInfo,
    };
    pubId += 1;
    // const publisher = normalizeFieldValue(fields['publisher']) as string | undefined;

    if (currentYear !== sharedInfo.year) {
      currentYear = sharedInfo.year;

      if (!props.hideYears && yearIdx > 0) {
        publications.push(
          <Header key={`publication-year-${currentYear}-header`} variant="h2">
            {currentYear}
          </Header>,
        );
      }
      yearIdx += 1;
    }

    publications.push(
      <PublicationItem key={`citation-${sharedInfo.itemKey}-item`} {...sharedInfo} />,
    );
  }

  return props.title ? (
    <Container
      header={
        <Header actions={props.actions} variant="h2">
          {props.title}
        </Header>
      }
    >
      <SpaceBetween children={publications} direction="vertical" size="s" />
    </Container>
  ) : (
    <SpaceBetween children={publications} direction="vertical" size="s" />
  );
}
