import { Header, Box, HelpPanel, Icon, Link } from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

export function MoralitiesHelp() {
  const navigate = useNavigate();

  return (
    <HelpPanel
      footer={
        <div>
          <Header variant="h3">
            Learn more <Icon name="external" />
          </Header>
          <ul>
            <li>
              <Link
                href="https://ojs.aaai.org/index.php/ICWSM/article/view/19360"
                target="_blank"
                external
              >
                A journey of stance, ontological commitments and moral foundations
              </Link>
            </li>
            <li>
              <Link
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9148945/"
                target="_blank"
                external
              >
                Identifying the framing of beliefs towards vaccine confidence in Twitter discourse
              </Link>
            </li>
          </ul>
        </div>
      }
      header={<Header variant="h2">About Moral Foundations</Header>}
    >
      <div>
        <Header variant="h3">What are Moral Foundations?</Header>
        <Box variant="p">
          <Link href="https://www.jstor.org/stable/20027945" target="_blank" external>
            Moral Foundations Theory
          </Link>{' '}
          is a theory of moral psychology that proposes that there are (at least) five universal
          moral foundations that underlie human morality. These foundations are{' '}
          <Link
            href="/frames/moralities/Care"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Care
          </Link>
          /
          <Link
            href="/frames/moralities/Harm"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Harm
          </Link>
          ,{' '}
          <Link
            href="/frames/moralities/Fairness"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Fairness
          </Link>
          /
          <Link
            href="/frames/moralities/Cheating"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Cheating
          </Link>
          ,{' '}
          <Link
            href="/frames/moralities/Loyalty"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Loyalty
          </Link>
          /
          <Link
            href="/frames/moralities/Betrayal"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Betrayal
          </Link>
          ,{' '}
          <Link
            href="/frames/moralities/Authority"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Authority
          </Link>
          /
          <Link
            href="/frames/moralities/Subversion"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Subversion
          </Link>
          , and{' '}
          <Link
            href="/frames/moralities/Purity"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Purity
          </Link>
          /
          <Link
            href="/frames/moralities/Degradation"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Degradation
          </Link>
          . These foundations are thought to be universal, in the sense that they are shared by all
          cultures, and likely have their origins in evolutionary biology, social psychology, and
          cultural evolution.
        </Box>
        <Header variant="h3">Why consider Moral Foundations?</Header>
        <Box variant="p">
          Moral Foundations Theory is a useful framework for understanding the moral dimensions of a
          topic, or in our case, a vaccine hesitancy framing. It can be used to identify the moral
          dimensions of a framing, and to understand how people think about those dimensions. It can
          also be used to understand how people think about the moral dimensions of a framing in
          relation to other framings.
        </Box>
        <Header variant="h3">How were Moral Foundations assigned?</Header>
        <Box variant="p">
          Each frame was annotated with the Moral Foundation values that were most salient to the
          frame. Annotations were performed by NLP researchers and Public Health experts, where
          disagreements were resolved through discussion.
        </Box>
      </div>
    </HelpPanel>
  );
}
