import SideNavigation, { SideNavigationProps } from '@cloudscape-design/components/side-navigation';
import BreadcrumbGroup, {
  BreadcrumbGroupProps,
} from '@cloudscape-design/components/breadcrumb-group';
import { useLocation, useNavigate } from 'react-router-dom';

const HOME = { text: 'Max Weinzierl', href: '/' };
const items: SideNavigationProps.Item[] = [
  { type: 'link', text: 'Home', href: '/' },
  {
    type: 'expandable-link-group',
    text: 'Publications',
    href: '/publications',
    items: [
      {
        type: 'link',
        text: 'Google Scholar',
        href: 'https://scholar.google.com/citations?user=G5BQKugAAAAJ',
        external: true,
      },
      {
        type: 'link',
        text: 'Semantic Scholar',
        href: 'https://www.semanticscholar.org/author/Maxwell-Alan-Weinzierl/122449521',
        external: true,
      },
      {
        type: 'link',
        text: 'DBLP',
        href: 'https://dblp.org/pid/277/7481.html',
        external: true,
      },
      {
        type: 'link',
        text: 'ORCID',
        href: 'https://orcid.org/0000-0002-8049-7453',
        external: true,
      },
    ],
  },
  { type: 'link', text: 'Projects', href: '/projects' },
  { type: 'link', text: 'Experience', href: '/experience' },
  { type: 'link', text: 'Education', href: '/education' },
  {
    type: 'expandable-link-group',
    text: 'Frame Analysis',
    href: '/frames',
    items: [
      {
        type: 'link',
        text: 'Categories',
        href: '/frames/categories',
      },
      {
        type: 'link',
        text: 'Moral Foundations',
        href: '/frames/moralities',
      },
      {
        type: 'link',
        text: 'Questions',
        href: '/frames/questions',
      },
    ],
  },
  { type: 'link', text: 'Frame Discovery', href: '/discovery' },
  { type: 'divider' },
  {
    type: 'link',
    text: 'LinkedIn',
    href: 'https://www.linkedin.com/in/max-weinzierl/',
    external: true,
  },
  {
    type: 'link',
    text: 'GitHub',
    href: 'https://github.com/Supermaxman',
    external: true,
  },
  {
    type: 'link',
    text: 'Tutoring',
    href: 'https://www.wyzant.com/Tutors/TutoringToTheMax',
    external: true,
  },
];

export function Breadcrumbs({ items }: { items: BreadcrumbGroupProps.Item[] }) {
  const navigate = useNavigate();

  return (
    <BreadcrumbGroup
      items={[HOME, ...items]}
      onClick={(event) => {
        if (event.detail.external) {
          return;
        }
        event.preventDefault();
        navigate(event.detail.href);
      }}
    />
  );
}

export type LocalNavigationProps = {
  readonly setNavigationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly navigationOpen: boolean;
};

export function LocalNavigation() {
  const navigate = useNavigate();
  const location = useLocation();

  // get first two path segments
  const segments = location.pathname.split('/');

  let activeHref = '/';

  if (segments.length === 2) {
    activeHref = location.pathname;
  } else if (segments.length >= 3) {
    if (segments[2].startsWith('F')) {
      activeHref = `/${segments[1]}`;
    } else {
      activeHref = `/${segments[1]}/${segments[2]}`;
    }
  }

  return (
    <SideNavigation
      activeHref={activeHref}
      header={{ href: '/', text: 'Max Weinzierl' }}
      items={items}
      onFollow={(event) => {
        if (event.detail.external) {
          return;
        }
        event.preventDefault();
        navigate(event.detail.href);
      }}
    />
  );
}
