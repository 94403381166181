import {
  AppLayout,
  ContentLayout,
  Box,
  Container,
  Header,
  Link,
  SpaceBetween,
  Grid,
} from '@cloudscape-design/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faExternalLink } from '@fortawesome/free-solid-svg-icons';

import { LocalNavigation, Breadcrumbs, LocalNavigationProps } from '../navigation';

import Projects, { Project } from './projects';

import './index.css';
import { useNavigate } from 'react-router-dom';

export type ShortProjectItemProps = {
  project: Project;
};

export function ShortProjectItem(props: ShortProjectItemProps) {
  const navigate = useNavigate();
  let hashIndex = window.location.href.indexOf('#');

  if (hashIndex === -1) {
    hashIndex = window.location.href.length;
  }

  const projectLink =
    window.location.href.slice(0, hashIndex) + '/projects#' + props.project.projectId;
  const localLink = '/projects#' + props.project.projectId;
  const image = <img alt={`Logo of ${props.project.title}`} src={props.project.logo} />;

  const imageContent = (
    <Link
      href={projectLink}
      variant="info"
      onFollow={(e) => {
        e.preventDefault();
        navigate(localLink);
      }}
    >
      {image}
    </Link>
  );

  return (
    <Container
      key={props.project.projectId}
      header={
        <Header description={<Box variant="p">{props.project.subtitle}</Box>} variant="h3">
          <Box variant="h4">
            {props.project.title}
            <Box padding="xxs" variant="small">
              <SpaceBetween direction="horizontal" size="xxs">
                <Link
                  href={projectLink}
                  variant="info"
                  onFollow={(e) => {
                    e.preventDefault();
                    navigate(localLink);
                  }}
                >
                  <FontAwesomeIcon icon={faLink} />
                </Link>
                {props.project.url ? (
                  <Link href={props.project.url} target="_blank" variant="info">
                    <FontAwesomeIcon icon={faExternalLink} title="External project link" />
                  </Link>
                ) : undefined}
              </SpaceBetween>
            </Box>
          </Box>
        </Header>
      }
      id={props.project.projectId}
      media={{
        content: imageContent,
        position: 'side',
        width: '40%',
      }}
      disableContentPaddings
    />
  );
}

export type ProjectsListProps = {
  title?: string;
  actions?: React.ReactNode;
  projects: Project[];
};

export function ShortProjectsList(props: ProjectsListProps) {
  const projects: React.ReactNode[] = props.projects.map((project) => (
    <ShortProjectItem project={project} />
  ));

  return props.title ? (
    <Container
      header={
        <Header actions={props.actions} variant="h2">
          {props.title}
        </Header>
      }
    >
      <SpaceBetween children={projects} direction="vertical" size="s" />
    </Container>
  ) : (
    <SpaceBetween children={projects} direction="vertical" size="s" />
  );
}
