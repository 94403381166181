import { Box, Container, Table, TableProps } from '@cloudscape-design/components';
import { Macro, String as StringNode, Environment } from '@unified-latex/unified-latex-types';
import React from 'react';

import { ContentSections } from './content';
import { visitGroupChild, visitNode } from './parsing';

export function createTable(environment: Environment, table: TexTable, root: ContentSections) {
  for (const child of environment.content) {
    // TODO consider other macros like centering and small
    if (child.type === 'macro') {
      const macro = child as Macro;

      if (macro.content === 'caption') {
        const groupElements: React.ReactNode[] = [];
        const children = macro.args![macro.args!.length - 1].content;

        for (const child of children) {
          visitGroupChild(child, groupElements, root);
        }
        table.setCaption(groupElements);
      } else if (macro.content === 'label') {
        const labelContent = macro.args![macro.args!.length - 1].content;
        const labelRef = labelContent
          .map((x) => x as StringNode)
          .map((x) => x.content)
          .join('');
        table.setLabel(labelRef);
      } else {
        // console.log('SKIPPED ENVIRONMENT MACRO');
        // console.log(macro);
      }
    } else if (child.type === 'environment' && child.env === 'tabular') {
      const tableEnvironment = child as Environment;

      if (tableEnvironment.args) {
        // TODO consider other args for tabular
        const columnArgs = tableEnvironment.args[tableEnvironment.args.length - 1];

        for (const arg of columnArgs.content) {
          if (arg.type === 'string') {
            const columnType = (arg as StringNode).content;
            // TODO do something with | in table
            const keepTypes = columnType.split('').filter((x) => x !== '|');

            for (const keepType of keepTypes) {
              table.pushColumnType(keepType);
            }
          } else {
            // console.log('SKIPPED ENVIRONMENT ARG');
            // console.log(arg);
          }
        }
      }

      table.pushSubTable();

      for (const contentNode of tableEnvironment.content) {
        if (contentNode.type === 'macro') {
          const macro = contentNode as Macro;

          if (macro.content === 'toprule') {
            // TODO add top line to table
          } else if (macro.content === 'midrule') {
            // If the current table has headers then we want to create a new table
            if (table.hasHeaders()) {
              table.pushSubTable();
            } else {
              table.setHeaders();
            }
          } else if (macro.content === 'bottomrule') {
            // TODO add bottom line to table
          } else if (macro.content === 'hline') {
            // TODO add horizontal line to table
          } else if (macro.content === 'parbreak') {
            // nothing to do here
          } else if (macro.content === '\\') {
            // new row
            table.pushRow();
          } else {
            visitGroupChild(macro, table.getContents(), root);
          }
        } else if (contentNode.type === 'group') {
          visitGroupChild(contentNode, table.getContents(), root);
        } else if (contentNode.type === 'string') {
          const string = contentNode as StringNode;

          if (string.content === '&') {
            // new column
            table.pushColumn();
          } else {
            table.pushContent(string.content);
          }
        } else if (contentNode.type === 'whitespace') {
          const lastContents = table.getContents();

          if (lastContents.length > 0 && lastContents[lastContents.length - 1] !== ' ') {
            table.pushContent(' ');
          }
        } else if (contentNode.type === 'inlinemath') {
          visitNode(contentNode, table.getContents(), root);
        } else if (contentNode.type === 'parbreak') {
          // nothing to do here
        } else if (contentNode.type === 'comment') {
          // nothing to do here
        } else {
          console.error('UNKNOWN TABLE CONTENT', contentNode);
        }
      }
    } else if (child.type === 'parbreak' || child.type === 'whitespace') {
      // nothing to do here
    } else if (child.type === 'string') {
      // consider supporting [t] type positioning
    } else {
      console.error('UNKNOWN TABLE CHILD', child);
    }
  }
}

export class TexSubTable {
  readonly tableId: number;
  readonly subTableId: number;
  headers: TexRow[];
  rows: TexRow[];
  readonly columnTypes: string[];

  constructor({
    tableId,
    subTableId,
    columnTypes,
  }: {
    tableId: number;
    subTableId: number;
    columnTypes: string[];
  }) {
    this.headers = [];
    this.rows = [new TexRow()];
    this.columnTypes = columnTypes;
    this.tableId = tableId;
    this.subTableId = subTableId;
  }
  getRow() {
    return this.rows[this.rows.length - 1];
  }
  getContents() {
    return this.rows[this.rows.length - 1].getColumnItems();
  }
  pushRow() {
    this.rows.push(new TexRow());
  }
  pushColumn() {
    this.rows[this.rows.length - 1].pushColumn();
  }
  pushContent(item: React.ReactNode) {
    this.rows[this.rows.length - 1].pushColumnItem(item);
  }
  hasHeaders() {
    return this.headers.length > 0;
  }
  setHeaders() {
    this.headers = this.rows;
    this.rows = [new TexRow()];
  }
  render() {
    let colIdx = 0;
    const columnDefinitions: TableProps.ColumnDefinition<React.ReactNode[][]>[] = [];
    const items = this.rows.map((row) => row.columns).slice(0, this.rows.length - 1);
    const headers = this.headers.slice(0, this.headers.length - 1);
    const colFunc = (cIdx: number) => {
      return (row: React.ReactNode[][]) => {
        return (
          <Box
            children={row[cIdx]}
            key={`table-${this.tableId}-column-${colIdx}-row-${cIdx}`}
            variant="p"
          />
        );
      };
    };

    for (const colType of this.columnTypes) {
      const columnItems: React.ReactNode[] = [];

      for (const colRow of headers) {
        for (const colRowItem of colRow.columns[colIdx]) {
          columnItems.push(colRowItem);
        }
      }
      // TODO align text using colType
      columnDefinitions.push({
        id: `${colIdx}`,
        header: (
          <Box children={columnItems} key={`table-${this.tableId}-column-${colIdx}`} variant="p" />
        ),
        cell: colFunc(colIdx),
      });
      colIdx += 1;
    }

    return (
      <Table
        key={`table-${this.tableId}-sub-${this.subTableId}`}
        columnDefinitions={columnDefinitions}
        items={items}
        variant="embedded"
        wrapLines
      />
    );
  }
}

export class TexTable {
  readonly tableId: number;
  readonly subTables: TexSubTable[];
  readonly columnTypes: string[];
  caption: React.ReactNode[];
  label: string;

  constructor({ tableId }: { tableId: number }) {
    this.columnTypes = [];
    this.tableId = tableId;
    this.caption = [];
    this.label = '';
    this.subTables = [];
  }

  pushSubTable() {
    this.subTables.push(
      new TexSubTable({
        tableId: this.tableId,
        subTableId: this.subTables.length,
        columnTypes: this.columnTypes,
      }),
    );
  }
  getContents() {
    return this.subTables[this.subTables.length - 1].getContents();
  }
  setLabel(label: string) {
    this.label = label;
  }
  pushRow() {
    this.subTables[this.subTables.length - 1].pushRow();
  }
  pushColumnType(type: string) {
    this.columnTypes.push(type);
  }
  pushColumn() {
    this.subTables[this.subTables.length - 1].pushColumn();
  }
  pushContent(item: React.ReactNode) {
    this.subTables[this.subTables.length - 1].pushContent(item);
  }
  hasHeaders() {
    return this.subTables[this.subTables.length - 1].hasHeaders();
  }
  setHeaders() {
    this.subTables[this.subTables.length - 1].setHeaders();
  }
  setCaption(caption: React.ReactNode[]) {
    this.caption = caption;
  }
  render() {
    return (
      <Container
        children={this.subTables.map((subTable) => subTable.render())}
        key={this.label}
        footer={
          <Box
            children={[
              <Box key={`table-${this.tableId}-caption-name`} fontWeight="bold" variant="span">
                Table {this.tableId}:{' '}
              </Box>,
              ...this.caption,
            ]}
            key={`table-${this.tableId}-caption`}
            textAlign="center"
            variant="p"
          />
        }
        id={this.label}
      />
    );
  }
}

class TexRow {
  readonly columns: React.ReactNode[][];
  constructor() {
    this.columns = [[]];
  }
  pushColumnItem(item: React.ReactNode) {
    this.columns[this.columns.length - 1].push(item);
  }
  pushColumn() {
    this.columns.push([]);
  }
  getColumnItems() {
    return this.columns[this.columns.length - 1];
  }
}
