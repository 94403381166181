import {
  AppLayout,
  ContentLayout,
  Header,
  SpaceBetween,
  Container,
  ColumnLayout,
  Box,
} from '@cloudscape-design/components';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';

import { LocalNavigation, Breadcrumbs, LocalNavigationProps } from '../../../navigation';
import { StanceTimeGraph } from '../../stance/time';
import { FramesTable } from '../../table';
import { CategoryStanceDonut } from '../../stance/category';
import { MoralStanceDonut } from '../../stance/moral';
import { EmptyState, ErrorState, LoadingState } from '../../../common/data/loading';
import { FrameContext } from '../../../common/data/context';

export default function QuestionPage(props: LocalNavigationProps) {
  const params = useParams();

  const state = useContext(FrameContext).state;

  const questionId = params.questionId ?? '';
  let content = (
    <Container>
      <Box>Question data not found</Box>
    </Container>
  );
  let header = <Header variant="h1">Unknown Question</Header>;

  if (state.loading) {
    content = <LoadingState />;
  }

  if (state.error) {
    content = (
      <SpaceBetween direction="vertical" size="xxs">
        <EmptyState />
        <ErrorState error={state.error} onRetry={state.retry} />
      </SpaceBetween>
    );
  }

  if (questionId !== undefined && state.value !== undefined) {
    const info = state.value?.questionLookup[questionId];

    if (info !== undefined) {
      header = (
        <SpaceBetween direction="vertical" size="xxs">
          <Header variant="h1">
            COVID-19 {info.question.q_theme} Question {info.question.q_id}
          </Header>
          <Header variant="h2">{info.question.q_text}</Header>
        </SpaceBetween>
      );
      content = (
        <SpaceBetween direction="vertical" size="l">
          <Container header={<Header variant="h2">Question Categories & Moral Foundations</Header>}>
            <ColumnLayout columns={2} variant="text-grid">
              <CategoryStanceDonut max_date={info.max_date} stanceInfo={info.taxStance} />
              <MoralStanceDonut max_date={info.max_date} stanceInfo={info.mfStance} />
            </ColumnLayout>
          </Container>
          <StanceTimeGraph
            acceptSeries={info.acceptSeries}
            header={<Header variant="h2">Question Tweets Over Time</Header>}
            rejectSeries={info.rejectSeries}
          />
          <FramesTable
            frames={info.frames}
            header={
              <SpaceBetween direction="vertical" size="xxxs">
                <Header variant="h2">
                  Frames Discovered with {info.question.q_theme} Question
                </Header>
                <Header variant="h3">{info.question.q_text}</Header>
              </SpaceBetween>
            }
            questions={state.value!.questions}
            disableQuestions
            disableUrlQueryParams
          />
        </SpaceBetween>
      );
    }
  }

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Frames', href: '/frames' },
            { text: 'Questions', href: '/frames/questions' },
            { text: params.questionId ?? '?', href: '' },
          ]}
        />
      }
      content={
        <ContentLayout header={<Container header={header} disableContentPaddings />}>
          {content}
        </ContentLayout>
      }
      contentType="default"
      navigation={<LocalNavigation />}
      navigationOpen={props.navigationOpen}
      navigationWidth={250}
      toolsHide
      onNavigationChange={(event) => props.setNavigationOpen(event.detail.open)}
    />
  );
}
