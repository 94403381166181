import { useContext, useState } from 'react';
import {
  AppLayout,
  ContentLayout,
  Header,
  Grid,
  Box,
  Link,
  SpaceBetween,
  Container,
} from '@cloudscape-design/components';

import { LocalNavigation, Breadcrumbs, LocalNavigationProps } from '../../navigation';
import { EmptyState, ErrorState, LoadingState } from '../../common/data/loading';
import { FrameContext } from '../../common/data/context';

import { MoralitiesTable } from './table';
import { MoralitiesHelp } from './help';

function MoralitiesContent({ setOpen }: { setOpen: (open: boolean) => void }) {
  const state = useContext(FrameContext).state;

  if (state.loading) {
    return <LoadingState />;
  }

  if (state.error) {
    return (
      <SpaceBetween direction="vertical" size="xxs">
        <EmptyState />
        <ErrorState error={state.error} onRetry={state.retry} />
      </SpaceBetween>
    );
  }

  return (
    <Grid gridDefinition={[{ colspan: { default: 12 } }]}>
      <MoralitiesTable
        header={
          <Header
            description={
              <Box variant="p">
                Filter, Sort, and View COVID-19 Vaccine Moral Foundations. What is a moral
                foundation? See{' '}
                <Link variant="info" onFollow={() => setOpen(true)}>
                  Info
                </Link>
              </Box>
            }
            variant="h2"
          >
            Moral Foundations{' '}
            <Link variant="info" onFollow={() => setOpen(true)}>
              Info
            </Link>
          </Header>
        }
        moralities={state.value!.moralInfos}
      />
    </Grid>
  );
}

export default function MoralitiesPage(props: LocalNavigationProps) {
  const [open, setOpen] = useState(false);

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Frames', href: '/frames' },
            { text: 'Moral Foundations', href: '/frames/moralities' },
          ]}
        />
      }
      content={
        <ContentLayout
          header={
            <Container
              header={
                <Header
                  description="Investigating the Moral Foundations Underlying COVID-19 Vaccine Hesitancy on Twitter"
                  variant="h1"
                >
                  COVID-19 Vaccine Moral Foundations
                </Header>
              }
              disableContentPaddings
             />
          }
        >
          <MoralitiesContent setOpen={setOpen} />
        </ContentLayout>
      }
      contentType="default"
      navigation={<LocalNavigation />}
      navigationOpen={props.navigationOpen}
      navigationWidth={250}
      tools={<MoralitiesHelp />}
      toolsOpen={open}
      toolsWidth={500}
      onNavigationChange={(event) => props.setNavigationOpen(event.detail.open)}
      onToolsChange={(event) => setOpen(event.detail.open)}
    />
  );
}
